import React from "react";
import ShareComponent from "../components/ShareComponent";
import PieChart from "../components/PieChart";

const sampleData = [
  { label: "Under 21", value: 59, color: "#008CFF" },
  { label: "21-23", value: 39, color: "#E63976" },
  { label: "24-26", value: 36, color: "#662D91" },
  { label: "26+", value: 14, color: "#FFD700" },
];

//   <PieChart data={sampleData} />;

const ChapterEight = () => {
  return (
    <div className="bg-[#0C4354] w-full py-20">
      <section
        id="how-participants-perform-byage"
        className="max-w-screen-xl mx-auto px-4 md:px-6"
      >
        {/* <h2 className="text-lg text-primary font-jakarta">Chapter Eight</h2> */}
        <div className="flex items-center gap-2 max-w-xl ">
          <div className="spinning-circle animate-spin-slow bg-yellow-400 rounded-full w-4 h-6 mb-2"></div>

          <h1 className="text-xl md:text-4xl font-black text-white font-jakarta">
            How Applicants Perform On Online Test: By Class Of Age
          </h1>
        </div>
        <div>
          <p className="text-white text-base lg:text-xl font-medium my-6 font-jakarta">
            <span className="font-black">Under-21</span> candidates have the
            highest pass rates, showcasing stronger cognitive agility and
            familiarity with academic assessments.
          </p>
          <p className="text-white text-base lg:text-xl font-medium my-6 font-jakarta">
            <span className="font-black">
              Candidates over 26 struggle the most.
            </span>
            There was generally a noticeable decline in the performance of
            candidates with age.
          </p>
        </div>
        <PieChart data={sampleData} />
        <ShareComponent
          sectionId="how-participants-perform-byage"
          shareText="Applicants under 21 have the highest pass rates on pre-employment online tests."
          shareImage="https://public-ng.s3.amazonaws.com/Performance-Age.png"
          hrColor="bg-white"
          textColor="text-white"
          buttonColor="bg-transparent"
          buttonTextColor="text-white"
        />
      </section>
    </div>
  );
};

export default ChapterEight;
