import React, { useState } from 'react'
import HeroImage from "../../../assets/images/Hero.png"
import { useValidateEmail } from '../../../hooks/landingpage/useLandingPage'
import { toast } from 'react-toastify'
import { Events, animateScroll as scroll, scrollSpy } from 'react-scroll';
import Swal from 'sweetalert2';
import ReactGA from "react-ga"


interface HeroPayload{
  setFormDetails:any
}
function Hero({setFormDetails}:HeroPayload) {
  const [email, setEmailAddress]= useState("")
  const validateAPI= useValidateEmail()
  const validateEmail=()=>{

    ReactGA.event({
      category: "User",
      action: "TAP",
      label: "User tried to validate email",
    });
    

validateAPI.mutate({email},{
  onSuccess(data){
    setEmailAddress("")
    
    if(data?.messsage==="You have already signed up for the TAP program"){
      Swal.fire({
        title: "Thank you for joining the TAP program..",
        text: "You will have acs, expert-led webinars and j",
        icon: "success",
        cancelButtonColor:"#8CAF4B",
        confirmButtonText:"close"
      });
    
    }
    if(data?.message==="You have successfully validated your email address."){
      Swal.fire({
        title: "You have an existing Testassessify account!",
        text: "As an existing TestAssessify user, you can proceed to join the TAP program by filling out the form.",
        icon: "success",
        confirmButtonText:"Proceed",
        confirmButtonColor:"#8CAF4B"
      });
      // scroll to form
      sessionStorage.setItem("email_verified_at",data?.data?.email_verified_at)
      sessionStorage.setItem("email",data?.data?.user?.email)
      sessionStorage.setItem("first_name",data?.data?.user?.first_name)
      sessionStorage.setItem("last_name",data?.data?.user?.last_name)
      sessionStorage.setItem("phone_number",data?.data?.user?.phone_number)
      setFormDetails({
        "email_verified_at":data?.data?.email_verified_at,
        "email":data?.data?.user?.email,
        "first_name":data?.data?.user?.first_name,
        "last_name":data?.data?.user?.last_name,
        "phone_number":data?.data?.user?.phone_number,
      })
      const name=   document.getElementById("first_name")
      Events.scrollEvent.register('begin', (to, element) => {
        console.log('begin', to, element);
      });
      
      scrollSpy.update();
      
      scroll.scrollTo(name?.offsetTop||0)
      document.getElementsByName("first_name")[0].focus();

    }
    else if(data?.message==="Please check your mailbox for your verification mail"){
      Swal.fire({
        title: "Verify Your Email.",
        text: "Please check your email for a link to verify your email address.",
        icon: "info",
         confirmButtonColor:"#8CAF4B"
      });
      // toast.info("Kindly check your mail for next step to take")
    }
   console.log({data})
  },
  onError(err:any){
    console.log({err})
    toast.dismiss()
    toast.error(err?.response?.data?.message)
  }
   })
  }

  return (
    <div id='hero-section' className=' p-4 md:flex justify-center items-center md:px-8 md:pt-0 '>
      <div className="md:flex-[0.6] md:pl-6 lg:pl-12 ">
        <h2 className="text-5xl font-medium mb-6 max-w-xl text-black dark:text-white ">TAP-in to get noticed by recruiters</h2>
        <p className=" mb-1 text-black dark:text-white">Join the TAP program to get noticed by recruiters and become No 1 candidate on their shortlist.</p>
        <p className="mb-8 text-black dark:text-white">Unlock your potential with test preparations, tailored courses, expert-led webinars, and job fairs and a thriving community. Take the first step toward landing your dream job</p>
     <div className="flex gap-4">

     {/* <input type="text" value={email} onChange={e=>setEmailAddress(e.target.value)} placeholder='Enter your email address' className='input input-bordered max-w-72 mb-2 '/> */}
      <a  
       href='#registration-form'
       className={`btn rounded-3xl bg-[#8CAF4B] bg-[#8CAF4B] text-white `}
        //onClick={validateEmail}
      >
        {"TAP To Get Started"}
        </a>
     </div>
      </div>
      <div className="md:flex-[0.4] ">
        <img src={HeroImage} alt="hero" className="" />
      </div>
    </div>
  )
}

export default Hero
