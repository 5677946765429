import { useEffect, useState } from "react";
import Header from "./components/Header";
import { FaArrowDownLong } from "react-icons/fa6";
import GroundnutImage from "../../assets/images/report/groundnut.png";
import IncomeChart from "./components/IncomeChart";
import BarChartSection from "./components/BarchartSection";
import HorizontalBarChartSection from "./components/HorizontalBarChartSection";
import TestimonialSection from "./components/TestimonialSection";
import HorizontalBarChart from "./components/HorizontalBarChart";
import AnimatedPieChart from "./components/AnimatedPieChart";
import BarChart from "./components/BarChart";
import SectionOne from "./Sections/SectionOne";
import ChapterTwo from "./Sections/ChapterTwo";
import ChapterThree from "./Sections/ChapterThree";
import ChapterFour from "./Sections/ChapterFour";
import ChapterFive from "./Sections/ChapterFive";
import ChapterSix from "./Sections/ChapterSix";
import ChapterSeven from "./Sections/ChapterSeven";
import ChapterEight from "./Sections/ChapterEight";
import ChapterNine from "./Sections/ChapterNine";
import ChapterTen from "./Sections/ChapterTen";
import ChapterOne from "./Sections/ChapterOne";
import { useLocation } from "react-router-dom";
import ByAssessments from "./Sections/ByAssessments";
import ApplicantsPipeline from "./Sections/ApplicantsPipeline";
import UniversityAssessment from "./Sections/UniversityAssessment";
import GraduateRecruitment from "./Sections/GraduateRecruitment";
import GraduateSnapshot from "./components/GraduateSnapshot";
import { motion } from "framer-motion";
import { FiCheckCircle } from "react-icons/fi";
const Report = () => {
  useEffect(() => {
    if (window.location.hash) {
      const sectionId = window.location.hash.replace("#", "");
      setTimeout(() => {
        document
          .getElementById(sectionId)
          ?.scrollIntoView({ behavior: "smooth" });
      }, 500);
    }
  }, []);

  const logoVariants = {
    hidden: { opacity: 0, y: -20 },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.6,
        ease: "easeOut",
      },
    },
  };

  return (
    <div class="relative bg-[#060F21] text-white min-h-screen flex flex-col">
      <main className="">
        <Header />
        <div className="bg-[#8BC6A6]">
          <div className="max-w-screen-xl mx-auto   md:pt-40 pb-20 px-4 sm:px-6 xl:px-40">
            {/* Logo */}
            <motion.div
              className="hidden md:flex items-center "
              variants={logoVariants}
            >
              <FiCheckCircle className="w-10 h-10 text-white mr-2" />
              <span className="text-white text-3xl font-bold">
                TestAssessify
              </span>
            </motion.div>
            <GraduateRecruitment />
          </div>
        </div>
        {/* <GraduateSnapshot /> */}
        {/* <SectionOne />  */}
        <ChapterOne />
        <ChapterTwo />
        <ChapterThree />
        <ChapterFour />
        <ChapterFive />
        <ChapterSix />
        <UniversityAssessment />
        <ByAssessments />
        <ChapterSeven />
        <ChapterEight />
        <ChapterNine />

        <ApplicantsPipeline />
        <ChapterTen />

        {/* <section className="max-w-screen-xl mx-auto mt-40 ">
          <div className="flex flex-col gap-3 text-white text-sm md:text-base max-w-5xl">
            <p className="text-white font-jakarta">
              In 2023, we asked 1000 Nigerians about their relationship with
              money.
            </p>
            <p className="text-white font-jakarta">
              We learned a lot from our findings — lessons we have implemented
              through small and big changes to Piggyvest’s features. We have
              gotten to know our users better and learned that there is no such
              thing as too much data if you want to solve money problems.
            </p>
            <p className="text-white font-jakarta">
              So, this year, from June to August, we surveyed over 10,000
              Nigerians — of different ages, genders, and income brackets -
              about their saving and spending habits. We also asked about debt,
              inflation, business, and their financial plans for the future.
            </p>
            <p className="text-white font-jakarta">
              The results are in, and they suggest that a lot can change in a
              year.
            </p>
            <span
              onClick={scrollToNextSection}
              className="cursor-pointer font-jakarta text-white hover:underline flex items-center gap-x-2 font-medium"
            >
              Scroll
              <span>
                <FaArrowDownLong />
              </span>
            </span>
          </div>
        </section> */}
        {/* <section className="max-w-screen-xl mx-auto mt-20">
          <IncomeChart />
        </section> */}
        {/* <section id="next-section" className="mt-20">
          <p className="text-white mb-4 font-jakarta">
            37% of our respondents said they earn below ₦100k.
          </p>
          <div class="relative z-20 flex items-center overflow-hidden">
            <div class="container relative flex items-center gap-10  ">
              <div class="relative hidden sm:block sm:w-1/3 lg:w-2/5 flex justify-center items-center">
                <img src={GroundnutImage} className="max-w-xs  md:max-w-md" />
              </div>
              <div class="relative z-20 flex flex-col">

                <p class="text-sm  sm:text-base text-white font-jakarta">
                  Adaora*, a 23-year-old salary earner based in Enugu:
                </p>
                <h1 class="md:max-w-xs mt-6 flex font-jakarta flex-col text-xl font-bold leading-none text-[#0089FF] font-bebas-neue sm:text-3xl dark:text-white">
                  “It’s like peanuts these days, earning ₦100k.”
                </h1>
              </div>
            </div>
          </div>

          
        </section> */}
        {/* <BarChartSection /> */}
        {/* <HorizontalBarChartSection /> */}
        {/* <TestimonialSection /> */}
        {/* <HorizontalBarChart /> */}

        {/* <BarChart data={data}/> */}
      </main>
    </div>
  );
};

export default Report;
