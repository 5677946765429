import React, { useState, useEffect } from "react";
import { Pie } from "react-chartjs-2";
import { Chart, ArcElement, Tooltip } from "chart.js";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { motion } from "framer-motion";
import { useInView } from "react-intersection-observer";

Chart.register(ArcElement, Tooltip, ChartDataLabels);

const PieChart = ({ data }) => {
  const { ref, inView } = useInView({ triggerOnce: true, threshold: 0.5 });
  const [animate, setAnimate] = useState(false);

  useEffect(() => {
    if (inView) setAnimate(true);
  }, [inView]);

  const chartData = {
    labels: data.map((item) => item.label),
    datasets: [
      {
        data: animate ? data?.map((item) => item.value) : Array(data?.length).fill(0),
        backgroundColor: data?.map((item) => item.color),
        borderWidth: 0,
        borderColor: "#fff",
        hoverOffset: 8,
      },
    ],
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: { display: false }, // Disable legend
      tooltip: { enabled: true ,callbacks: {
        label: function (tooltipItem) {
          const value = tooltipItem.raw; // Get the existing value
          return `${value}%`; // Append the '%' symbol
        },
      },},
      datalabels: {
        color: "#fff",
        font: { weight: "normal", size: 24 },
        formatter: (value, context) => context.chart.data?.labels[context.dataIndex],
      },
    },
    animation: {
      animateRotate: true,
      animateScale: true,
    },
  };

  return (
    <motion.div
      ref={ref}
      className="flex justify-center items-center my-10 relative w-full max-w-[400px] mx-auto"
      initial={{ opacity: 0, scale: 0.8 }}
      animate={animate ? { opacity: 1, scale: 1 } : {}}
      transition={{ duration: 0.8 }}
    >
      <div className="relative w-full aspect-square">
        <Pie data={chartData} options={options} />
      </div>
    </motion.div>
  );
};

export default PieChart;
