import { useMutation, useQuery } from "react-query"
import { instance2 as instance  } from "../instance"
import { is } from "cypress/types/bluebird";


export interface IRegisterPayload{
        email: string; // Email address of the user
        name: string; // Name of the user
        phoneNumber: string; // Phone number of the user
        platformSegmentId?: string; // Mautic segment ID (e.g., "463" for TAP)
        platform?: string; // Platform the request is coming from (e.g., "tap")
        emailVerificationUrl?: string; // URL for email verification
        payment?: any,
}
export interface ICheckDiscountPayload {
    "discountCode": string;
    "platform": "tap";
}

export interface ITractVisitorsPayload{
    platform: "tap",
    pageUrl: string
}
export const useRegistration=()=>{
    const register=async(payload:IRegisterPayload)=>{
        // const {data}= await instance.post(`/v2/tap-signup`, payload)
        const {data}= await instance.post(`/wishlists`, payload)
        return data
    }
    const mutate= useMutation(register)
    return mutate
}


interface IValidateEmailPayload{
email:string
}
export const useValidateEmail=()=>{
    const validate= async(payload:IValidateEmailPayload)=>{
        // const {data}= await instance.post(`/v2/validate-email`, {...payload})
        const {data}= await instance.post(`/wishlists/tap/validate-email`, {...payload})
        return data
    }
const mutate = useMutation(validate)
return mutate
}

export const useTrackVisitors=()=>{
    const validate= async(payload:ITractVisitorsPayload)=>{
        // const {data}= await instance.post(`/v2/validate-email`, {...payload})
        const {data}= await instance.post(`/visitors/track`, {...payload})
        return data
    }
const mutate = useMutation(validate)
return mutate
}


interface IValidateOTPPayload{
    email:string,
    otp:string|number,
}
export const useValidateOTP=()=>{
    const validate= async(payload:IValidateOTPPayload)=>{
        const {data}= await instance.post(`/wishlists/tap/verify-email`, payload)
        return data
    }
const mutate = useMutation(validate)
return mutate
}

export const useGetDiscountCode=()=>{
    const validate= async()=>{
        const {data}= await instance.get(`/products?platform=tap`)
        return data
    }
    const {data, isLoading} = useQuery("discount", validate)
    return {isLoading, data}    
}

export const useCheckDiscountCode=(id: string)=>{
    
    const validate= async(payload: ICheckDiscountPayload)=>{
        const {data}= await instance.post(`/products/${id}/discount-amount`, payload)
        return data
    }
    const mutate = useMutation(validate)
    return {mutateAsync:mutate.mutateAsync, isLoading:mutate.isLoading}
}



