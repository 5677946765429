import { useEffect, useState, useRef } from "react";
import moneyImage from "../../../assets/images/report/money.png";
import adaoraImage from "../../../assets/images/report/adaora.jpeg";
import { FaQuoteLeft, FaQuoteRight } from "react-icons/fa";

const incomeData = [
  { label: "No income", value: 28, color: "bg-blue-500" },
  { label: "<N100,000", value: 57, color: "bg-pink-500" },
  { label: "N100k-N249k", value: 41, color: "bg-green-500" },
  { label: "N250k-N499k", value: 28, color: "bg-blue-300" },
  { label: "N500k-N999k", value: 24, color: "bg-purple-500" },
  { label: ">N1m", value: 22, color: "bg-yellow-500" },
];

export default function IncomeChart() {
  const [animateIndex, setAnimateIndex] = useState(-1);
  const sectionRef = useRef(null);

  // useEffect(() => {
  //   incomeData.forEach((_, index) => {
  //     setTimeout(() => setAnimateIndex(index), index * 500); // Delay each bar
  //   });
  // }, []);

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          incomeData.forEach((_, index) => {
            setTimeout(() => setAnimateIndex(index), index * 300);
          });
        }
      });
    });

    if (sectionRef.current) {
      observer.observe(sectionRef.current);
    }

    return () => {
      if (sectionRef.current) {
        observer.unobserve(sectionRef.current);
      }
    };
  }, []);

  return (
    <section ref={sectionRef} className=" text-white px-2 md:px-0">
      {/* Header */}
      <div className="text-left">
        {/* <h2 className="text-lg text-white font-jakarta italic font-medium">Chapter One</h2> */}
        <div className="flex flex-col">
          <div className="flex items-center gap-2 max-w-xl ">
            <div className="spinning-circle animate-spin-slow bg-yellow-400 rounded-full w-4 h-6 mb-2"></div>
            <h1 className="text-xl md:text-4xl font-black text-white  font-jakarta">
              Foreword
            </h1>
          </div>
          <div>
            <h1 className="text-base md:text-xl font-bold text-white font-jakarta mb-5">
              Unlocking Graduate Talent: Insights For Recruiters And Job Seekers
            </h1>
          </div>
          {/* <div className="mb-5 flex flex-col gap-y-5">
            <p className="text-white text-sm md:text-base lg:text-xl font-jakarta">
              The journey of recruitment is much like navigating a maze—complex,
              challenging, but ultimately rewarding for those who persevere. As
              the Nigerian graduate job market grows increasingly competitive,
              with over 600,000 graduates entering the workforce annually, the
              need for strategic insights has never been greater. This report,
              distilled from over 200,000 applications and assessments in 2024,
              serves as a compass for both recruiters and job seekers alike.
            </p>
            <div className="col-span-8 w-full bg-[#F08B75] text-white p-4 rounded-3xl text-lg font-semibold flex flex-col items-center justify-center">
              <FaQuoteLeft className="text-3xl mb-2 self-start" />
              <p className="text-center max-w-2xl leading-relaxed text-white xl:text-2xl font-jakarta">
                The world of work is evolving rapidly, and our hiring practices
                must evolve even faster to stay ahead.
              </p>
              <FaQuoteRight className="text-3xl mt-2 self-end" />
            </div>
            <p className="text-white text-sm md:text-base lg:text-xl font-jakarta">
              As the ancient Greek philosopher Heraclitus once said, “Change is
              the only constant in life.” This rings true in the evolving
              landscape of recruitment, where technology, age biases, and
              shifting candidate behaviors are reshaping how talent is
              identified and acquired. Similarly, Maya Angelou’s words remind
              us, “Do the best you can until you know better. Then when you know
              better, do better.” This report equips HR professionals and job
              applicants with the knowledge to do better—whether in refining
              recruitment processes or preparing for the demands of a
              competitive job market.
            </p>
            <p className="text-white text-sm md:text-base lg:text-xl font-jakarta">
              From the critical role of pre-employment tests to the surprising
              trends in candidate performance across universities and age
              groups, this report offers actionable insights. I highly recommend
              this report to both audiences—it is not just a reflection of where
              we are, but a roadmap to where we need to go.
            </p>

           
          </div> */}
          <div className="mt-12 mb-10 bg-purple-900 p-6 md:p-12 rounded-lg flex flex-col md:flex-row items-center gap-6 f">
            <div className="relative block p-1 bg-lime-500 rounded-full">
              <img
                src={adaoraImage} // Replace with actual avatar image
                alt="Adora Ikwuemesi"
                className="mx-auto object-cover rounded-full h-40 w-40"
              />
            </div>
            <div className="flex-1 text-white">
              <p className="text-md md:text-lg  text-white ext-center md:text-left  font-jakarta">
               "I highly recommend this report to both audiences—it is not just
                a reflection of where we are, but a roadmap to where we need to
                go. The world of work is evolving rapidly, and our hiring
                practices must evolve even faster to stay ahead."
              </p>
              <div className="mt-5">
                <p className="text-sm md:text-lg !m-0 text-white font-bold font-jakarta">
                  — Adora Ikwuemesi
                </p>
                <p className="text-sm text-white !m-0 font-jakarta">
                  Director, Kendor Consulting and Organiser of the HR Bootcamp
                </p>
              </div>
            </div>
          </div>
          {/* <div className="">
         
            <div className="relative flex flex-col justify-end ">
              <p className="mb-2 rotate-2 font-bold font-jakarta">we asked:</p>
              <div className="bg-white font-jakarta  px-6 py-2 w-max text-black font-bold text-2xl shadow-md  transform rotate-2">
                How much do you earn?
              </div>
            </div>
          </div> */}
        </div>
      </div>

      {/* Content */}
      {/* <div className="max-w-screen-lg mx-auto  relative">


        <div
          className="absolute inset-0 opacity-100 bg-cover bg-center bg-no-repeat"
          style={{ backgroundImage: `url(${moneyImage})` }}
        ></div>
     
        <div
          className="absolute inset-0 opacity-20 bg-cover bg-center bg-no-repeat"
          style={{ backgroundImage: `url(${moneyImage})` }}
        ></div>

 
        <div className="relative flex items-end  mt-8 h-[450px]">
          {incomeData.map((data, index) => (
            <div
              key={index}
              className="relative flex flex-col items-center flex-1 group"
            >
     
              <div
                className={`${data.color} opacity-70 group-hover:opacity-100 transition-all duration-600 w-full flex items-end justify-center`}
                style={{
                  height:
                    animateIndex >= index ? `${data.value * 2.5}px` : "0px",
                  transition: "height 0.8s ease-in-out",
                }}
              >
             
                <span className="text-white text-4xl font-bold  opacity-100">
                  {data.value}%
                </span>
              </div>
            </div>
          ))}
        </div>

      </div>
      <div className="relative z-10 flex justify-between mt-4 px-20">
        {incomeData.map((data, index) => (
          <p key={index} className="text-sm text-gray-300 font-jakarta">
            {data.label}
          </p>
        ))}
      </div> */}
    </section>
  );
}
