import React from 'react';
import { motion } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import ShareComponent from '../components/ShareComponent';
// import { CheckCircle } from 'lucide-react';

const UniversityAssessment = () => {
  const [ref, inView] = useInView({
    triggerOnce: true,
    threshold: 0.1,
  });

  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.2,
      },
    },
  };

  const itemVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.6,
      },
    },
  };

  const circleVariants = {
    hidden: { scale: 0, opacity: 0 },
    visible: (custom) => ({
      scale: 1,
      opacity: 1,
      transition: {
        delay: custom * 0.2,
        duration: 0.8,
        type: 'spring',
        stiffness: 100,
      },
    }),
  };

  const percentageVariants = {
    hidden: { opacity: 0, scale: 0.5 },
    visible: {
      opacity: 1,
      scale: 1,
      transition: {
        delay: 0.4,
        duration: 0.8,
        type: 'spring',
        stiffness: 100,
      },
    },
  };

  return (
    <motion.div
      ref={ref}
      className="w-full bg-[#B3DCBC]  bg-mint  overflow-hidden relative"
      variants={containerVariants}
      initial="hidden"
      animate={inView ? 'visible' : 'hidden'}
    >
      {/* Background pattern */}
      <div className="absolute inset-0 opacity-10 overflow-hidden">
        <div className="font-jakarta text-dark-navy text-[400px] font-bold transform -rotate-12 translate-x-1/4 -translate-y-1/4">
          {/* 40% */}
        </div>
      </div>

      <div className="relative max-w-screen-xl mx-auto z-10 p-8 md:p-12 lg:p-16">
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-8 lg:gap-16">
          {/* Left column - Text content */}
          <motion.div className="space-y-6">
            <motion.h1 
              className="text-xl md:text-4xl  font-bold text-[#2C2565] leading-tight"
              variants={itemVariants}
            >
              How Applicants Perform On Online Assessment: By University
            </motion.h1>
            
            <motion.p variants={itemVariants} className="text-[#2C2565] text-lg font-jakarta">
              Private universities lead with a <span className="font-bold font-jakarta">40%</span> pass rate, showing a stronger readiness for job employment test.
            </motion.p>
            
            <motion.p variants={itemVariants} className="text-[#2C2565] text-lg font-jakarta">
              Federal universities are closely behind, debunking the assumption that private institutions vastly outperform them.
            </motion.p>
            
            <motion.p variants={itemVariants} className="text-[#2C2565] text-lg font-jakarta">
              Majority of applicants are from the federal universities, which confirms that they remain a critical talent pipeline.
            </motion.p>
          </motion.div>

          {/* Right column - Percentage visualization */}
          <div className="flex items-center justify-center">
            <motion.div 
              className="relative"
              variants={percentageVariants}
            >
              <div className="font-jakarta text-pink-primary text-[200px] md:text-[280px] font-bold leading-none">
                40<span className="text-[100px] md:text-[140px] align-top">%</span>
              </div>
              <div className="font-jakarta text-center md:text-left md:ml-20 mt-6 text-lg text-dark-navy font-jakarta  font-black">
                Pass rate for private universities
              </div>
            </motion.div>
          </div>
        </div>

        {/* Bottom circles */}
        <div className="mt-12 mb-6 md:mt-16 flex items-center flex-wrap justify-center gap-4 md:gap-0">
          <motion.div 
            custom={0}
            variants={circleVariants}
            className="w-36 h-36 md:w-64 md:h-64 rounded-full bg-purple-primary flex flex-col items-center justify-center text-white text-center -mr-4 md:-mr-6 z-40"
          >
            <div className="text-4xl md:text-5xl font-bold font-jakarta">40%</div>
            <div className="text-sm md:text-base mt-1 font-jakarta">Private<br />Universities</div>
          </motion.div>
          
          <motion.div 
            custom={1}
            variants={circleVariants}
            className="w-36 h-36 md:w-56 md:h-56 rounded-full bg-purple-secondary flex flex-col items-center justify-center text-white text-center -mr-4 md:-mr-6 z-30"
          >
            <div className="text-4xl md:text-5xl font-bold font-jakarta">36%</div>
            <div className="text-sm md:text-base mt-1 font-jakarta">Federal<br />Universities</div>
          </motion.div>
          
          <motion.div 
            custom={2}
            variants={circleVariants}
            className="w-36 h-36 md:w-52 md:h-52 rounded-full bg-pink-primary flex flex-col items-center justify-center text-white text-center -mr-4 md:-mr-6 z-20"
          >
            <div className="text-4xl md:text-5xl font-bold font-jakarta">34%</div>
            <div className="text-sm md:text-base mt-1 font-jakarta">State<br />Universities</div>
          </motion.div>
          
          <motion.div 
            custom={3}
            variants={circleVariants}
            className="w-36 h-36 md:w-44 md:h-44 rounded-full bg-pink-tertiary flex flex-col items-center justify-center text-white text-center z-10"
          >
            <div className="text-4xl md:text-5xl font-bold font-jakarta">26%</div>
            {/* <div className="text-sm md:text-base mt-1 font-jakarta flex items-start "><p className="text-red-500 text-4xl leading-none">*</p> Foreign<br />Universities</div> */}
            <div className="text-sm md:text-base mt-1 font-jakarta">
  <span className="relative">
    <span className="absolute -top-1  -left-3 text-red-500 text-2xl">*</span>
    Foreign
  </span>
  <br />Universities
</div>

          </motion.div>
         
    
        </div>
        <div className=" flex items-start justify-center  text-center my-6 text-sm  md:text-lg text-dark-navy font-jakarta  font-medium">
        <span className="text-red-500 text-2xl">*</span> Foreign universities are mainly universities in neighboring West African countries.
              </div>
        <ShareComponent
          sectionId="how-participants-perform-by-university"
          shareText="Private universities lead with a 40% pass rate on pre-
employment online tests"
          shareImage="https://public-ng.s3.amazonaws.com/Performance-Class-of-Degree.png"
          hrColor="bg-[#2C2565]"
          textColor="text-[#2C2565]"
          buttonColor="bg-[#2C2565]"
          buttonTextColor="text-white"
        />
      </div>
    </motion.div>
  );
};

export default UniversityAssessment;