import React from 'react'
import ThankYou, { ExistingCustomer } from './components/ThankYou'
import Swal from 'sweetalert2'
import { useNavigate } from 'react-router-dom'

interface ICompleted{
    duplicate?:boolean
}
function CompletedForm(props:ICompleted) {
const navigate= useNavigate()

    Swal.fire({
        title: "Thank you for joining the TAP program..",
        text: `You will have access to test preparation, 
       tailored courses, expert-led webinars and job fairs.
        We look forward to supporting you on your journey 
        to success.`,
        icon: "success",
        confirmButtonColor:"#8CAF4B",
        confirmButtonText:"close",
        
      }).then(()=>{
navigate('/tap')
      })

  return (
    <div className='bg-[#8CAF4B] min-h-[100vh] min-w-[100vw]'>
      {
        !props.duplicate?
        <div>

       </div>
        :<ExistingCustomer/>
      }
    </div>
  )
}

export default CompletedForm
