import { useState } from "react";
import { FaWhatsapp, FaLink, FaLinkedinIn, FaTimes } from "react-icons/fa";
import { motion } from "framer-motion";
import { Modal, Input } from "antd";
import pdf from "../../../assets/images/report/data/testassesify-report.pdf";
import Logo from "../../../components/Logo";
import "antd/dist/antd.css"; // Use this for Antd v4
import toast from "react-hot-toast";
import { report } from "../../../api/report";

const Header = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);
  const [formData, setFormData] = useState({ name: "", email: "" });
  const [loading, setLoading] = useState(false);

  const pdfUrl =
    "https://public-ng.s3.amazonaws.com/Graduate%20Recruitment%20Snapshot%202025%20%281%29.pdf";

  const handleDownload = async () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  // const closeConfirmationModal = () => {
  //   setIsConfirmationModalOpen(false);
  //   // setTimeout(() => {
  //       window.open(pdfUrl, "_blank");
  //     // }, 3000);
  // };

  const handleInputChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const shareToWhatsApp = () => {
    const whatsappUrl = `https://api.whatsapp.com/send?text=Check out this report: ${pdfUrl}`;
    window.open(whatsappUrl, "_blank");
  };

  const shareToLinkedIn = () => {
    const linkedInUrl = `https://www.linkedin.com/shareArticle?url=${pdfUrl}`;
    window.open(linkedInUrl, "_blank");
  };

  const copyToClipboard = () => {
    navigator.clipboard.writeText(pdfUrl);
    toast.success("PDF link copied to clipboard!");
  };

  const submitForm = async () => {
    if (!formData.name || !formData.email) {
      toast.success("Please fill in your name and email");
      return;
    }
    setLoading(true);
  
    const payload = {
      downloadLink: pdfUrl,
      email: formData.email,
      name: formData.name,
    };
  
    try {
      const response = await report.shareReport(payload);
  
      // Clear form after success
      setFormData({ name: "", email: "" });
  
      localStorage.setItem("hasDownloadedReport", response?.data?.redirect);
      closeModal();  
      setIsConfirmationModalOpen(true); // Open confirmation modal
    } catch (error) {
      toast.error("Something went wrong. Please try again.");
      console.error(error);
    } finally {
      setLoading(false); // Stop loader
    }
  };
  
  const closeConfirmationModal = () => {
    setIsConfirmationModalOpen(false);
  
    // Check localStorage for redirect status
    const shouldRedirect = localStorage.getItem("hasDownloadedReport") === "true";
  
    if (shouldRedirect) {
      window.open(pdfUrl, "_blank"); // Open report in a new tab
    }
  };
  

  // const submitForm = async () => {
  //   if (!formData.name || !formData.email) {
  //     toast.success("Please fill in your name and email");
  //     return;
  //   }
  //   setLoading(true);

  //   const payload = {
  //     downloadLink: pdfUrl,
  //     email: formData.email,
  //     name: formData.name,
  //   };
  //   try {
  //     const response = await report.shareReport(payload);
  //     // toast.success(response?.message);

  //     // Clear form after success
  //     setFormData({ name: "", email: "" });

  //     // Wait for the toast to be displayed before opening the report
  //     // setTimeout(() => {
  //     //   window.open(pdfUrl, "_blank");
  //     // }, 3000); // adjust the timeout as needed
  //     closeModal();
  //     localStorage.setItem("hasDownloadedReport", response?.data?.redirect);
  //     setIsConfirmationModalOpen(true); // Open confirmation modal
  //   } catch (error) {
  //     toast.error("Something went wrong. Please try again.");
  //     console.error(error);
  //   } finally {
  //     setLoading(false); // Stop loader
  //   }
  // };

  const isFormValid = formData.name && /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(formData.email);

  return (
    <>
      <motion.header
        initial={{ opacity: 0, y: -50 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
        className="fixed top-0 left-0 w-full px-4 sm:px-6  py-4 bg-white backdrop-blur-md z-40 shadow-md"
      >
        <nav className="max-w-7xl mx-auto">
          <div className="flex items-center justify-between">
            {/* Logo Section */}
            <motion.div
              whileHover={{ scale: 1.05 }}
              className="flex items-center"
            >
              <Logo />
            </motion.div>

            {/* Buttons Section */}
            <div className="flex items-center  md:space-x-3">
              {/* Download Button */}
              <button
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
                onClick={handleDownload} // Open modal on click
                className="bg-primary text-white px-2 md:px-4 py-3 rounded-full text-sm md:text-base"
              >
                Download Report
              </button>

              {/* Share Buttons (Hidden on Mobile) */}
              <div className="hidden sm:flex items-center space-x-2">
                <span className="text-black font-bold">Share</span>

                <motion.button
                  whileHover={{ scale: 1.1 }}
                  whileTap={{ scale: 0.9 }}
                  className="p-2 bg-gray-700 rounded-full text-white"
                  onClick={copyToClipboard}
                >
                  <FaLink />
                </motion.button>

                <motion.button
                  whileHover={{ scale: 1.1 }}
                  whileTap={{ scale: 0.9 }}
                  className="p-2 bg-gray-700 rounded-full text-white"
                  onClick={shareToWhatsApp}
                >
                  <FaWhatsapp />
                </motion.button>

                <motion.button
                  whileHover={{ scale: 1.1 }}
                  whileTap={{ scale: 0.9 }}
                  className="p-2 bg-gray-700 rounded-full text-white"
                  onClick={shareToLinkedIn}
                >
                  <FaLinkedinIn />
                </motion.button>
              </div>
            </div>
          </div>
        </nav>
      </motion.header>

      {/* Download Modal */}
      <Modal
        open={isModalOpen} // Ensure it's controlled by state
        onCancel={closeModal}
        footer={null}
        closable={false} // Disable default close button
        bodyStyle={{ backgroundColor: "#060F21", padding: "1rem" }}
        width="600px"
        wrapClassName="rounded-2xl"
        // bodyStyle={{ padding: 0 }} // Remove default padding
      >
        {/* Close Button */}
        <motion.button
          whileHover={{ scale: 1.1 }}
          whileTap={{ scale: 0.9 }}
          onClick={closeModal}
          className="absolute top-3 right-3 w-12 h-12 text-2xl bg-gray-700 rounded-full flex items-center justify-center text-white hover:bg-gray-600 transition-colors"
          style={{ zIndex: 1 }} // Ensure it's above other content
        >
          &times;
        </motion.button>

        {/* Modal Content */}
        <motion.div
          initial={{ opacity: 0, scale: 0.8 }}
          animate={{ opacity: 1, scale: 1 }}
          transition={{ duration: 0.3 }}
          className="text-center bg-[#060F21] p-6 mt-10"
        >
          {/* <h2 className="text-xl font-jakarta font-semibold mb-4 text-white">
            Download Report
          </h2> */}
          <p className="text-gray-400 mb-4 font-normal mt-4 font-jakarta italic">
            Enter your details to receive the report via email.
          </p>

          <div className="flex flex-col md:flex-row gap-4">
            {/* Name Input */}
            <div className="w-full text-left">
              <label className="text-white font-jakarta text-sm">
                Name <span className="text-red-500">*</span>
              </label>
              <input
                name="name"
                placeholder="Enter your name"
                value={formData.name}
                onChange={handleInputChange}
                className="p-3 rounded-full w-full bg-[#1A2332] custom-input-text"
               
              />
            </div>

            {/* Email Input */}
            <div className="w-full text-left">
              <label className="text-white font-jakarta text-sm">
                Email <span className="text-red-500">*</span>
              </label>
              <input
                name="email"
                placeholder="Enter your email"
                value={formData.email}
                onChange={handleInputChange}
                className="p-3 rounded-full w-full bg-[#1A2332] custom-input-text"
              />
            </div>
          </div>

          <div className="mt-10">
            <button
              onClick={submitForm}
              disabled={loading || !isFormValid} 
              className={`rounded-full p-3 text-white w-fit transition ease-in duration-200 text-base font-semibold shadow-md ${
                loading ? "bg-gray-500 cursor-not-allowed" : isFormValid ? "bg-primary hover:bg-green-600" : "bg-gray-400 hover:bg-gray-500"
              }`}

            >
              
              {loading ? (
                <div className="flex items-center gap-2">
                  <span className="h-5 w-5 border-2 border-white border-t-transparent rounded-full animate-spin"></span>
                  Processing...
                </div>
              ) : (
                "Download Report"
              )}
            </button>
          </div>
        </motion.div>
      </Modal>

      {/* Confirmation Modal */}
      {/* <Modal
        open={isConfirmationModalOpen}
        onCancel={closeConfirmationModal}
        footer={null}
        closable={false}
        bodyStyle={{ backgroundColor: "#060F21", padding: "1rem" }}
        width="400px"
        wrapClassName="rounded-2xl"
      >
        <motion.div
          initial={{ opacity: 0, scale: 0.8 }}
          animate={{ opacity: 1, scale: 1 }}
          transition={{ duration: 0.3 }}
          className="text-center bg-[#060F21] p-6 mt-10"
        >
          <p className="text-gray-400 mb-4 font-normal mt-4 font-jakarta italic">
            Please check your email (including spam folder) for the report.
          </p>
          <button
            onClick={closeConfirmationModal}
            className="rounded-full p-4 text-white w-fit transition ease-in duration-200 text-base font-semibold shadow-md bg-green-500 hover:bg-green-600"
          >
            OK
          </button>
        </motion.div>
      </Modal> */}

      {/* Confirmation Modal */}
<Modal
  open={isConfirmationModalOpen}
  onCancel={closeConfirmationModal}
  footer={null}
  closable={false} // Enable close button (X)
  bodyStyle={{ backgroundColor: "#8EAF55", padding: "1rem", borderRadius: "20px" }}
  width="400px"
  wrapClassName="rounded-2xl"
>
<motion.button
          whileHover={{ scale: 1.1 }}
          whileTap={{ scale: 0.9 }}
          onClick={closeConfirmationModal}
          className="absolute top-3 right-3 w-12 h-12 text-2xl bg-gray-700 rounded-full flex items-center justify-center text-white hover:bg-gray-600 transition-colors"
          style={{ zIndex: 1 }} // Ensure it's above other content
        >
          &times;
        </motion.button>

  <motion.div
    initial={{ opacity: 0, scale: 0.8 }}
    animate={{ opacity: 1, scale: 1 }}
    transition={{ duration: 0.3 }}
    className="text-center bg-primary] p-6 mt-10"
  >
    <p className="text-white mb-4 font-normal mt-4 text-base lg:text-xl font-jakarta italic">
     A copy of the report has been sent to your email address. Please check your email and download the report to ensure you receive future updates.🚀🚀
    </p>
  </motion.div>
</Modal>

    </>
  );
};

export default Header;