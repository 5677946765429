import React, { useState, useEffect } from "react";
import { Bar } from "react-chartjs-2";
import {
  Chart,
  BarElement,
  CategoryScale,
  LinearScale,
  Tooltip,
} from "chart.js";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { motion } from "framer-motion";
import { useInView } from "react-intersection-observer";

Chart.register(
  BarElement,
  CategoryScale,
  LinearScale,
  Tooltip,
  ChartDataLabels
);

const StackedBarChart = () => {
  const { ref, inView } = useInView({ triggerOnce: true, threshold: 0.3 });
  const [animate, setAnimate] = useState(false);

  useEffect(() => {
    if (inView) setAnimate(true);
  }, [inView]);

  const data = {
    labels: ["2.2", "2.1", "1"], // Degree Classes
    datasets: [
      {
        label: "% pass",
        data: animate ? [36, 35, 44] : [0, 0, 0], // Animate in sequence
        backgroundColor: "#8EAF55", // Green color
        barThickness: 40, // ✅ Keep bar thickness consistent
        maxBarThickness: 45,
        datalabels: {
          anchor: "center",
          align: "center",
          color: "white",
          font: { size: 26 },
          formatter: (value) => `${value}%`,
        },
      },
      {
        label: "% fail",
        data: animate ? [64, 65, 56] : [0, 0, 0], // Animate in sequence
        backgroundColor: "#D3D3D3", // Gray color
        barThickness: 40, // ✅ Keep bar thickness consistent
        maxBarThickness: 45,
        datalabels: { display: false }, // Hide fail labels
      },
    ],
  };

  const options = {
    indexAxis: "y", // Makes the bars horizontal
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: { display: true, position: "bottom" },
      datalabels: { display: true },
      title: {
        display: false,
        text: "Online Test Result by Class of Degree",
        font: { size: 16 },
      },
      tooltip: {
        enabled: true,
        callbacks: {
          label: function (tooltipItem) {
            return `${tooltipItem.dataset.label}: ${tooltipItem.raw}%`; // Append '%' to tooltip value
          },
        },
      },
    },
    scales: {
      x: {
        grid: { display: false }, // ✅ Removes x-axis grid lines
        stacked: true,
        ticks: { display: false }, // ✅ Hides numbers on x-axis
      },
      y: {
        categoryPercentage: 0.3, // ✅ Reduce category space (default is 0.8)
        barPercentage: 0.6, // ✅ Ensure bars take more space
        grid: { display: false }, // ✅ Removes y-axis grid lines
        stacked: true,
      },
    },
    animation: {
      duration: 1000, // Smooth animation
    },
  };
  

  return (
    <motion.div
      ref={ref}
      className="w-full max-w-4xl mx-auto px-4 my-10"
      initial={{ opacity: 0, y: 50 }}
      animate={animate ? { opacity: 1, y: 0 } : {}}
      transition={{ duration: 0.8 }}
    >
      <div className="relative w-full h-[300px] sm:h-[400px]">
        <Bar data={data} options={options} />
      </div>
    </motion.div>
  );
};

export default StackedBarChart;
