import React, { useEffect } from 'react'
import ReactGA from "react-ga4";
// import confetti from 'canvas-confetti'

ReactGA.initialize("G-E1Q00ZZMG1");
function ThankYou() {
  
useEffect(()=>{
  ReactGA.event({
    category: "TAP",
    action: "Thank you page",
    // label: "your label", // optional
    // value: 99, // optional, must be a number
    // nonInteraction: true, // optional, true/false
    // transport: "xhr", // optional, beacon/xhr/image
  });


  if(window.fbq){
  
  
    window.fbq('track', 'ThankYou', {
      content_name:"Thank you page",
      content_category:"TAP"
    });
  }
  else{
    console.log("FB Analytics not loaded")
  }
},[])
    // React.useEffect(() => {
    //   //   confetti({
    //   //     particleCount: 100,
    //   //     spread: 70,
    //   //     origin: { y: 0.6 }
    //   //   });
    //   // }, []);
  return (
    <div className='flex flex-col items-center justify-center p-4 h-72 bg-red-300 text-white'>
      <h2 className='text-2xl font-extrabold mb-4'>Hurray.</h2>
      <h2 className='text-2xl font-extrabold mb-4'>Thank You for joining the waitlist.</h2>
      <h4 className="font-semibold">Kindly Check your mail for the next step to take </h4>
    </div>
  )
}

export function ExistingCustomer(){
  return  <div className='flex flex-col items-center justify-center p-4 h-72 bg-red-700 text-white'>
    <h2 className='text-2xl font-extrabold mb-4'>Oops</h2>
    <h2 className='text-2xl font-extrabold mb-4'>Sorry You are already in the waitlist before</h2>
    <h4 className="font-semibold">Kindly Check your mail for the next step to take </h4>
  </div>
}

export default ThankYou
declare global{
  interface Window{
  fbq:(mthod:string, ...args:any[])=>void
  }
}
