import React from "react";
import HorizontalBarChartSection from "../components/HorizontalBarChartSection";
import ShareComponent from "../components/ShareComponent";
import StackedBarChart from "../components/StackedBarChart";

const ChapterSeven = () => {
  return (
    <div className="bg-white w-full py-20">
      <section
        id="how-participants-perform-bydegree"
        className="max-w-screen-xl mx-auto px-4 md:px-6"
      >
        {/* <h2 className="text-lg text-[#2C2565] font-jakarta">Chapter Seven</h2> */}
        <div className="flex items-center gap-4 max-w-xl ">
          <div className="spinning-circle animate-spin-slow bg-[#8EAF55] rounded-full w-4 h-6 mb-2"></div>

          <h1 className="text-xl md:text-4xl font-black text-[#2C2565] font-jakarta">
            How Applicants Perform On Online Test: By Class Of Degree
          </h1>
        </div>
        <p className="text-[#2C2565] text-base lg:text-xl font-jakarta !mb-2">
          As expected first-class graduates generally outperform their peers in
          online assessment.
        </p>
        <p className="text-[#2C2565] text-base lg:text-xl font-jakarta !mb-2">
          However, the difference in pass rates between Second-Class Upper (2:1)
          and Second-Class Lower (2:2) is minimal.
        </p>
        <StackedBarChart />
        {/* <HorizontalBarChartSection /> */}
        <ShareComponent
          sectionId="how-participants-perform-bydegree"
          shareText="The difference in pass rates between Second-Class Upper (2:1) and
              Second-Class Lower (2:2) is minimal."
          shareImage="https://public-ng.s3.amazonaws.com/Performance-Class-of-Degree.png"
          hrColor="bg-[#2C2565]"
          textColor="text-[#2C2565]"
          buttonColor="bg-[#2C2565]"
          buttonTextColor="text-white"
        />
      </section>
    </div>
  );
};

export default ChapterSeven;
