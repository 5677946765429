import { useState } from "react";
import { FaLink, FaWhatsapp, FaLinkedinIn } from "react-icons/fa";
import { motion, AnimatePresence } from "framer-motion";
import toast from "react-hot-toast";
import { report } from "../../../api/report";

const ShareComponent = ({
  shareText,
  shareImage,
  hrColor = "border-gray-200",
  textColor = "text-gray-900",
  buttonColor = "bg-blue-500",
  buttonTextColor = "text-white",
  sectionId,
}) => {
  const [showIcons, setShowIcons] = useState(false);
  //   const shareText = "Only about 1% of applicants make it to interviews.";
  //   const shareImage = "https://images.unsplash.com/photo-1573496359142-b8d87734a5a2?auto=format&fit=crop&q=80&w=200"; // Update this with your actual image URL

  // Generate the section link
  const sectionLink = `${window.location.origin}/report/#${sectionId}`;
// const sectionLink = `${window.location.origin}/report?section=${sectionId}`;


  const trackShare = async (location) => {
    const payload = {
      section: sectionId,
      location,
    };
    try {
      const response = await report.trackReport({ payload });
    } catch (error) {}
  };


  const copyToClipboard = async () => {
    const textToCopy = `${shareText}\n\nRead more: ${sectionLink}`;
    try {
      await navigator.clipboard.writeText(textToCopy);
      toast.success("Copied to clipboard!");
    } catch (err) {
      toast.error("Failed to copy text.");
    }
  };

  const shareToWhatsApp = () => {
    const url = `https://wa.me/?text=${encodeURIComponent(
      `${shareText}\n\nRead more: ${sectionLink}`
    )}`;
    trackShare("WhatsApp");
    window.open(url, "_blank");
  };
  
  const shareToLinkedIn = () => {
    const url = `https://www.linkedin.com/sharing/share-offsite/?url=${encodeURIComponent(
      `${shareText}\n\nRead more: ${sectionLink}`
    )}`;
    trackShare("LinkedIn");
    window.open(url, "_blank");
  };
  

//   const shareToWhatsApp = () => {
//     const url = `https://wa.me/?text=${encodeURIComponent(
//       `${shareText}\n\nRead more: ${sectionLink}`
//     )}`;
//     window.open(url, "_blank");
//   };

//   const shareToLinkedIn = () => {
//     const url = `https://www.linkedin.com/sharing/share-offsite/?url=${encodeURIComponent(
//       sectionLink
//     )}`;
//     window.open(url, "_blank");
//   };

  //   const copyToClipboard = async () => {
  //     const textToCopy = `${shareText}\n${shareImage}`;
  //     try {
  //       await navigator.clipboard.writeText(textToCopy);
  //       toast.success("Copied to clipboard!");
  //     } catch (err) {
  //       toast.error("Failed to copy text:", err);
  //       alert("Failed to copy");
  //     }
  //   };

  //   const shareToWhatsApp = () => {
  //     const url = `https://wa.me/?text=${encodeURIComponent(shareText)} ${shareImage}`;
  //     window.open(url, "_blank");
  //   };

  //   const shareToLinkedIn = () => {
  //     const url = `https://www.linkedin.com/sharing/share-offsite/?url=${encodeURIComponent(shareImage)}`;
  //     window.open(url, "_blank");
  //   };

  return (
    <div className="">
      <hr className={`${hrColor} border-2`} />
      <div className="flex flex-col md:flex-row md:justify-between py-4  items-center">
        <p
          className={`text-sm md:text-lg font-jakarta font-normal w-full md:w-3/5 md:w-2/3 lg:w-1/2 ${textColor}`}
        >
          {shareText}
        </p>

        {/* Share Button / Icons */}
        <div
          className="relative"
          onMouseEnter={() => setShowIcons(true)}
          onMouseLeave={() => setShowIcons(false)}
        >
          {!showIcons ? (
            <motion.button
              className={`font-jakarta border cursor-pointer ${buttonColor} hover:opacity-80 ${buttonTextColor} text-base md:text-lg  font-normal px-6 py-2 rounded-full transition-all`}
            >
              Share This
            </motion.button>
          ) : (
            <motion.div
              className="flex items-center space-x-2 bg-white p-2 shadow-md rounded-full cursor-pointer"
              initial={{ opacity: 0, scale: 0.8 }}
              animate={{ opacity: 1, scale: 1 }}
              exit={{ opacity: 0, scale: 0.8 }}
            >
              <motion.button
                whileHover={{ scale: 1.1 }}
                whileTap={{ scale: 0.9 }}
                className="p-2 bg-gray-700  rounded-full text-white"
                onClick={copyToClipboard}
              >
                <FaLink />
              </motion.button>

              <motion.button
                whileHover={{ scale: 1.1 }}
                whileTap={{ scale: 0.9 }}
                className="p-2 bg-green-500 rounded-full text-white"
                onClick={shareToWhatsApp}
              >
                <FaWhatsapp />
              </motion.button>

              <motion.button
                whileHover={{ scale: 1.1 }}
                whileTap={{ scale: 0.9 }}
                className="p-2 bg-blue-700 rounded-full text-white"
                onClick={shareToLinkedIn}
              >
                <FaLinkedinIn />
              </motion.button>
            </motion.div>
          )}
        </div>
      </div>
      <hr className={`${hrColor} `} />
    </div>
  );
};

export default ShareComponent;
