import React, { useEffect, useState } from "react";
import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { motion } from "framer-motion";
import { useInView } from "react-intersection-observer";
import "chartjs-plugin-datalabels";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const labels = [
  "Apply",
  "% qualified",
  "% attendance",
  "% pass round 1",
  "% pass round 2",
  
];

const percentages = [100, 33, 23, 7, 1];

const colors = [
  "#6B4AE8",
  "#F4844A",
  "#E84E8A",
  "#4AB1F4",
  "#7AB547",
];

const options = {
  responsive: true,
  plugins: {
    legend: { display: false },
    tooltip: { enabled: true, callbacks: {
      label: function (tooltipItem) {
        const value = tooltipItem.raw; // Get the existing value
        return `${value}%`; // Append the '%' symbol
      },
    }, },
    datalabels: {
      // Add this line
      color: "white",
      display: true,
      anchor: "center",
      align: "center",
      // offset: 0,
      font: {
        family: "Arial",
        size: 14,
        weight: "normal",
        style: "normal",
      },
      formatter: (value) => `${value}%`, 
    },
  },
  indexAxis: "y",
  scales: {
    x: {
      grid: {
        display: false, // ✅ Removes x-axis grid lines
      },
      // stacked: true,
      ticks: {
        display: false, // ✅ Hides numbers on x-axis
      },
      // beginAtZero: true, max: 100
    },
    y: {
      // categoryPercentage: 0.3, // ✅ Reduce category space (default is 0.8)
      // barPercentage: 0.6, // ✅ Ensure bars take more space
      grid: {
        display: false, // ✅ Removes y-axis grid lines
      },
      // stacked: true,
    },
  },
};

const HorizontalBarChart = () => {
  const { ref, inView } = useInView({ triggerOnce: true, threshold: 0.3 });
  const [animatedData, setAnimatedData] = useState(
    Array(percentages.length).fill(0)
  );

  useEffect(() => {
    if (inView) {
      percentages.forEach((value, index) => {
        setTimeout(() => {
          setAnimatedData((prev) => {
            const newData = [...prev];
            newData[index] = value;
            return newData;
          });
        }, index * 200);
      });
    }
  }, [inView]);

  const data = {
    labels,
    datasets: [
      {
        label: "Percentage",
        data: animatedData,
        backgroundColor: colors,
        borderRadius: 5,
        // text: percentages.map((value) => `${value}%`),
      },
    ],
  };

  return (
    <div ref={ref} className="text-white my-10">
      <div className=" w-full h-full relative">
        {labels.map((label, index) => (
          <motion.div
            key={index}
            initial={{ opacity: 0, x: -20 }}
            animate={{ opacity: inView ? 1 : 0, x: inView ? 0 : -20 }}
            transition={{ delay: index * 0.2 }}
            className="absolute left-0 text-lg font-bold pl-5 text-black"
            style={{ top: `${index * 35 + 16}px` }}
          >
            {/* {percentages[index]}% */}
          </motion.div>
        ))}
        <Bar data={data} options={options} />
      </div>
    </div>
  );
};

export default HorizontalBarChart;
