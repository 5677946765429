/* eslint-disable no-restricted-globals */
import React, { useState } from "react";
// import moment from "moment";
import { api } from "../../../api";
import toast from "react-hot-toast";
import { useCustomToast } from "../../../components/customtoast/CustomToastProvider";
import { profile } from "../../../api/profile";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { formatDate, reverseDate } from '../../../utils/dateFormatter';
import { useMutation } from "react-query";

function EditExperienceModal({ closeModal, data, refetch, refetchStatus }) {
 
  const user = JSON.parse(sessionStorage.getItem('user'))?.user
  const customToast = useCustomToast();
  const [values, setValues] = useState({
    name: data?.name,
    role: data?.role,
    start_date: reverseDate(data?.start_date),
    end_date: data?.end_date ? reverseDate(data?.end_date) : null,
    date: data?.date,
    description: data?.description,
  });

  const [validationErrors, setValidationErrors] = useState({});
  const [isCurrentlyWorking, setIsCurrentlyWorking] = useState(
    data?.end_date === null ? true : false
  );
  const { mutateAsync, isLoading } = useMutation(profile.updateUserExperiencev2)
  const handleCurrentlyWorkingChange = (e) => {
    setIsCurrentlyWorking(e.target.checked);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setValues({ ...values, [name]: value });
    // Clear the validation error for the field when the user starts typing again
    setValidationErrors((prevErrors) => ({
      ...prevErrors,
      [name]: "",
    }));
  };

  const handleDateInputChange = (name, value) => {
    setValues({ ...values, [name]: value });

    // Clear the validation error for the field when the user starts typing again
    setValidationErrors((prevErrors) => ({
      ...prevErrors,
      [name]: "",
    }));
  };

  const updateExperience = async (e) => {
    e.preventDefault();
    const requiredFields = ["name", "role", "description", "start_date"];
    const newValidationErrors = {};

    requiredFields.forEach((field) => {
      if (!values[field]) {
        newValidationErrors[field] = "This field is required.";
      }
    });

    if (Object.keys(newValidationErrors).length > 0) {
      setValidationErrors(newValidationErrors);
      return;
    }

    try {
      toast.loading("loading...");
      const response = await mutateAsync({
        id: data?.id, userId: user?.user_id, payload: { ...values,
          start_date: formatDate(values.start_date), end_date: 
          isCurrentlyWorking ? null : formatDate(values.end_date)
        },
      });
      toast.remove();
      customToast.success(response.message);
      await refetch();
      await refetchStatus();
      closeModal(false);
      return response;
    } catch ({ response }) {
      toast.remove();
      customToast.error(response?.data?.message);
    }
  };

  const deleteExperience = async (e) => {
    if (confirm("Are you sure you want to delete this")) {
      try {
        toast.loading("loading...");
        const res = await profile.deleteUserExperience({
          id: data?.id, userId: user?.user_id
        });
        toast.remove();
        customToast.success(res.message);
        await refetch();
        await refetchStatus();
        closeModal(false);
      } catch ({ response }) {
        toast.remove();
        customToast.error(response?.data?.message);
      }
    }
  };

  return (
    <div className="fixed z-[10] top-0 left-0 w-[100vw] h-[100vh] bg-black bg-opacity-50">
    <div className="flex items-center justify-center h-full w-full">
      <div className="w-[96%] overflow-y-scroll z-[10] h-[500px] md:h-[550px] md:w-[600px] bg-white rounded-[15px] p-8 md:p-4">
          <h3 className="text-lg leading-6 font-medium text-gray-900 mb-4">
            Edit Experience
          </h3>
          <div className="flex justify-end mb-4">
            {/* <div className="text-darkBlue text-xs font-medium"> </div> */}
            <div
              onClick={deleteExperience}
              className="text-[#F35B24] text-xs font-medium cursor-pointer hover:bg-gray-200 hover:p-2 hover:rounded"
            >
              Remove option
            </div>
          </div>
          <form onSubmit={updateExperience}>
            <div className="mb-4 ">
              <label
                htmlFor="name"
                className="text-gray-500 mb-2 text-sm font-medium"
              >
                Company name
                <span className="text-red-500 required-dot">*</span>
              </label>
              <input
                type="text"
                id="name"
                value={values.name}
                onChange={handleInputChange}
                className={`rounded-lg flex-1 appearance-none border ${
                  validationErrors.name ? "border-red-500" : "border-lightGrey"
                }  w-full py-4 px-4 bg-customGrey text-gray-700 placeholder-gray-400 shadow-sm text-sm focus:outline-none focus:ring-2 focus:ring-lightGrey focus:border-transparent`}
                name="name"
                placeholder="Name of company"
              />
              {validationErrors.name && (
                <p className="text-red-500 text-xs mt-1 text-right">
                  {validationErrors.name}
                </p>
              )}
            </div>
            <div className="flex flex-col md:flex-row  gap-x-2 mb-4">
              <div className="w-full  mb-4 md:mb-0">
                <div className=" flex flex-col">
                  <label
                    htmlFor="role"
                    className="text-gray-500 mb-2 text-sm font-medium"
                  >
                    Role
                    <span className="text-red-500 required-dot">*</span>
                  </label>
                  <input
                    type="text"
                    id="role"
                    name="role"
                    placeholder="Your role e.g Software Developer"
                    value={values.role}
                    onChange={handleInputChange}
                    className={`rounded-lg  flex-1 appearance-none border ${
                      validationErrors.role
                        ? "border-red-500"
                        : "border-lightGrey"
                    } py-4 px-4 bg-customGrey text-gray-700 placeholder-gray-400 shadow-sm text-sm focus:outline-none focus:ring-2 focus:ring-lightGrey focus:border-transparent`}
                  />
                  {validationErrors.role && (
                    <p className="text-red-500 text-xs mt-1 text-right">
                      {validationErrors.role}
                    </p>
                  )}
                </div>
              </div>
            </div>
            <div className="mb-4 flex gap-x-4">
              <div className="w-full md:w-1/2 mb-4 md:mb-0">
                <label
                  htmlFor="start_date"
                  className="text-gray-500 mb-1 block text-sm font-medium"
                >
                  Start Date
                  <span className="text-red-500 required-dot">*</span>
                </label>
                <DatePicker
                     selected={values.start_date}
                     placeholderText="start date (dd/mm/yyyy)"
                     dateFormat="dd/MM/yyyy"
                     className="border pr-16 border-slate-300 block w-full focus:border-primary active:border-primary py-3 px-3 rounded-lg text-xs text-slate-700 "
                     maxDate={values.end_date}
                     onChange={(value) => {
                      handleDateInputChange('start_date', value)
                    }}
                />
                {validationErrors.start_date && (
                  <p className="text-red-500 text-xs mt-1 text-right">
                    {validationErrors.start_date}
                  </p>
                )}
              </div>
              <div className="w-full md:w-1/2 mb-4 md:mb-0">
                <label
                  htmlFor="end_date"
                  className="text-gray-500 mb-2 text-sm font-medium"
                >
                  End Date
                </label>
                {!isCurrentlyWorking && (
                   <DatePicker
                    selected={values.end_date}
                    placeholderText="end date (dd/mm/yyyy)"
                    dateFormat="dd/MM/yyyy"
                    className="border pr-16 border-slate-300 block w-full focus:border-primary active:border-primary py-3 px-3 rounded-lg text-xs text-slate-700 "
                    minDate={values.start_date}
                    onChange={(value) => {
                      handleDateInputChange('end_date', value)
                    }}
                />
                )}
                <div className="mt-3">
                  <label
                    htmlFor="current-checkbox"
                    className="flex items-center"
                  >
                    <input
                      type="checkbox"
                      id="current-checkbox"
                      className=" dark:bg-white dark:text-white dark:border dark:border-gray-300 dark:appearance-none"
                      checked={isCurrentlyWorking}
                      onChange={handleCurrentlyWorkingChange}
                    />
                    <span className="ml-4 text-sm">I am currently working here</span>
                  </label>
                </div>
              </div>
            </div>
            <div className="mb-4">
              <label
                className="text-gray-500 mb-2 text-sm font-medium"
                htmlFor="description"
              >
                Details of what you did
                <span className="text-red-500 required-dot">*</span>
              </label>
              <textarea
                value={values.description}
                onChange={handleInputChange}
                className={`mt-4 flex-1 w-full px-4 py-2 text-sm text-gray-700 placeholder-gray-400 bg-customGrey border ${
                  validationErrors.description
                    ? "border-red-500"
                    : "border-lightGrey"
                } rounded-lg appearance-none focus:outline-none focus:ring-2 focus:ring-lightGrey focus:border-transparent`}
                id="description"
                name="description"
                placeholder="Details of what you did"
                rows="5"
                cols="40"
              ></textarea>
              {validationErrors.description && (
                <p className="text-red-500 text-xs mt-1 text-right">
                  {validationErrors.description}
                </p>
              )}
            </div>
            <div className="flex gap-x-10 justify-center">
              <button
                type="button"
                className="inline-flex justify-center rounded-md border border-armyGreen  shadow-sm px-8 py-2 md:px-16 md:py-2 bg-white text-xs font-medium text-darkBlue  sm:ml-3 sm:w-auto sm:text-sm mb-4 sm:mb-0"
                onClick={() => closeModal(false)}
              >
                Cancel
              </button>

              <button
                type="submit" disabled={isLoading}
                className="inline-flex justify-center rounded-md border border-none  shadow-sm px-8 py-2 md:px-16 md:py-2 bg-armyGreen text-xs font-medium text-white sm:ml-3 sm:w-auto sm:text-sm mb-4 sm:mb-0"
              >
                Update
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default EditExperienceModal;
