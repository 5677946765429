import React from "react";
import { motion } from "framer-motion";
import { useInView } from "react-intersection-observer";

const Counter = ({ value, suffix = "", duration = 2000 }) => {
  const [count, setCount] = React.useState(0);
  const { ref, inView } = useInView({
    triggerOnce: true,
    threshold: 0.1,
  });

  React.useEffect(() => {
    if (inView) {
      let startTime;
      const startValue = 0;
      const endValue = value;

      const animation = (currentTime) => {
        if (!startTime) startTime = currentTime;
        const progress = (currentTime - startTime) / duration;

        if (progress < 1) {
          const currentValue = Math.floor(
            startValue + (endValue - startValue) * progress
          );
          setCount(currentValue);
          requestAnimationFrame(animation);
        } else {
          setCount(endValue);
        }
      };

      requestAnimationFrame(animation);
    }
  }, [inView, value, duration]);

  return (
    <span ref={ref} className="font-black text-white font-jakarta">
      {count}
      {suffix}
    </span>
  );
};

const ApplicationStats = () => {
  const containerVariants = {
    hidden: {},
    visible: {
      transition: {
        staggerChildren: 0.2,
      },
    },
  };

  const itemVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.5,
      },
    },
  };

  return (
    <div className="py-16">
      <motion.div
        className="md:max-w-4xl mx-auto grid grid-cols-1 md:grid-cols-3 gap-6  md:auto-rows-fr"
        variants={containerVariants}
        initial="hidden"
        animate="visible"
      >
        <motion.div
          variants={itemVariants}
          className="bg-[#6B4AE8] md:flex flex-col md:justify-center items-center rounded-3xl h-fit md:h-full p-6  text-white md:col-span-2"
        >
          <h2 className="text-4xl md:text-6xl   font-black mb-4 text-white font-jakarta">
            Accountancy
          </h2>
          <p className="text-xl leading-tight font-semibold text-white font-jakarta">
            dominates applications, but STEM field come second.
          </p>
        </motion.div>
            {/* Age Card */}
            <motion.div
          variants={itemVariants}
          className="bg-[#F4844A] rounded-3xl h-fit md:h-full p-6 text-white"
        >
          <h2 className="text-5xl md:text-6xl font-black mb-4 text-white font-jakarta">
            Age
          </h2>
          <p className="text-xl leading-tight font-semibold text-white font-jakarta">
            and not class of degree class, is the number one reason for
            disqualification in graduate entry roles.
          </p>
        </motion.div>
        {/* 2/3 Card */}
        <motion.div
          variants={itemVariants}
          className="bg-[#E84E8A] rounded-3xl h-fit md:h-full p-6 text-white "
        >
          <div className="flex items-center  h-full">
            <div>
            <div className="text-5xl md:text-6xl  font-bold mb-4">
                <Counter value={66} suffix="%" />
              </div>
              <p className="text-2xl leading-tight font-semibold text-white font-jakarta">
                candidates apply despite not meeting job requirements.
              </p>
            </div>
          </div>
        </motion.div>

    

        {/* 70% Card */}
        <motion.div
          variants={itemVariants}
          className="bg-[#4AB1F4] rounded-3xl h-fit  md:h-full p-6 text-white"
        >
          <div className="flex items-center h-full">
            <div>
              <div className="text-5xl md:text-6xl  font-bold mb-4">
                <Counter value={70} suffix="%" />
              </div>
              <p className="text-xl leading-tight text-white font-semibold font-jakarta">
                of those who qualify actually show up for the next assessment
                stage.
              </p>
            </div>
          </div>
        </motion.div>

    

        {/* Federal Universities Card */}
        <motion.div
          variants={itemVariants}
          className="bg-[#7AB547] rounded-3xl h-fit md:h-full p-6 text-white"
        >
          <div className="h-full flex flex-col-reverse md:flex-col justify-between">
            <p className="text-xl leading-tight mb-4 text-white  font-semibold font-jakarta">
              Federal universities produce nearly half of all applicants.
              However, private universities show stronger outcomes in certain
              key metrics, such as online test pass rates.
            </p>
            <div className="text-5xl md:text-6xl mb-4 md:mb-0  font-black">
              <Counter value={50} suffix="%" />
            </div>
          </div>
        </motion.div>
      </motion.div>
    </div>
  );
};

export default ApplicationStats;
