import React, { useEffect, useState } from "react";
import Chart from "react-apexcharts";

const FunnelChart = () => {
  const categories = ["Application", "% Qualified", "% Attendance", "% Pass Round 1", "% Pass Round 2"];
  const colors = ["#2C2565", "#F4844A", "#E84E8A", "#4AB1F4", "#7AB547"]; // Custom bar colors

  const actualData = [100, 60, 40, 20, 10]; // Controls the shape
  const displayData = [100, 33, 23, 7, 1]; // Shown to users

  const [chartHeight, setChartHeight] = useState(400);
  const [barHeight, setBarHeight] = useState("70%");

  useEffect(() => {
    const updateChartSize = () => {
      if (window.innerWidth < 768) {
        setChartHeight(300); // Smaller height on mobile
        setBarHeight("90%"); // Increase bar height on mobile
      } else {
        setChartHeight(450); // Larger height on desktop
        setBarHeight("80%"); // Normal bar height on large screens
      }
    };

    updateChartSize();
    window.addEventListener("resize", updateChartSize);
    return () => window.removeEventListener("resize", updateChartSize);
  }, []);


  const options = {
    chart: { type: "bar", toolbar: { show: false }, animations: {
      enabled: false, // Set to false to disable animations completely
      easing: 'easeout', // Change easing type
      speed: 10000, // Reduce animation duration (default is 800)
    }, },
    plotOptions: {
      bar: {
        horizontal: true,
        isFunnel: true,
        distributed: true,
        borderRadius: 5,
        barHeight: barHeight, 
      },
    },
    xaxis: {
      categories,
      labels: { show: false },
      axisBorder: { show: false },
      axisTicks: { show: false },
    },
    yaxis: {
      labels: {
        style: { fontSize: "14px", fontWeight: "bold" },
        offsetX: 5,
      },
    },
    tooltip: {
      enabled: true,
      theme: "dark",
      y: {
        formatter: (val, { dataPointIndex }) => `${categories[dataPointIndex]}: ${displayData[dataPointIndex]}%`, // Show user-friendly values
      },
      custom: ({ series, seriesIndex, dataPointIndex }) => {
        return `
          <div 
          style="
            background: ${colors[dataPointIndex]};
            padding: 8px 12px;
            border-radius: 5px;
            color: white;
            font-size: 14px;
            font-weight: bold;
            text-align: left;
          ">
            ${categories[dataPointIndex]}: ${displayData[dataPointIndex]}%
          </div>
        `;
      },
    },
    dataLabels: {
      enabled: true,
      formatter: (_, { dataPointIndex }) => `${displayData[dataPointIndex]}%`, // Show user-friendly values
      style: { fontSize: "14px", fontWeight: "bold", colors: ["#fff"] },
      // offsetX: (val, { dataPointIndex }) => (dataPointIndex >= 3 ? -10 : 0),
    },
    colors,
    grid: { show: false },
    legend: { show: false },
  };

  const series = [{ name: "Percentage", data: actualData }]; // Use actualData for shape

  return (
    <div className="w-full mx-4 max-w-2xl mx-auto h-auto">
      <Chart options={options} series={series} type="bar" height={chartHeight} />
    </div>
  );
};

export default FunnelChart;
