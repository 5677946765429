"use client";

import { Pie } from "react-chartjs-2";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { motion, useAnimation } from "framer-motion";
import { useEffect, useState } from "react";
import { useInView } from "react-intersection-observer";

ChartJS.register(ArcElement, Tooltip, Legend);

const AnimatedPieChart = ({ data, colors }) => {
  const controls = useAnimation();
  const { ref, inView } = useInView({ triggerOnce: true, threshold: 0.5 });
  const [visibleData, setVisibleData] = useState([]);

  useEffect(() => {
    if (inView) {
      controls.start({ opacity: 1, scale: 1 });

      let delay = 0;
      setVisibleData([]);
      data.forEach((item, index) => {
        setTimeout(() => {
          setVisibleData((prev) => [...prev, item]);
        }, delay);
        delay += 500; // Delay each segment animation
      });
    }
  }, [inView, data]);

  const totalValue = data.reduce((acc, curr) => acc + curr.value, 0);

  const chartData = {
    labels: visibleData.map((d) => d.name),
    datasets: [
      {
        data: visibleData.map((d) => d.value),
        backgroundColor: colors.slice(0, visibleData.length),
        hoverOffset: 5, // Creates a hover effect
      },
    ],
  };

  // Generate dynamic label positions to avoid overlap
  const labelPositions = visibleData.map((_, index) => {
    const angle = (index / visibleData.length) * 2 * Math.PI;
    return {
      top: `${50 + 40 * Math.sin(angle)}%`,
      left: `${50 + 40 * Math.cos(angle)}%`,
      transform: "translate(-50%, -50%)",
    };
  });

  return (
    <motion.div
      ref={ref}
      initial={{ opacity: 0, scale: 0.8 }}
      animate={controls}
      transition={{ duration: 0.5 }}
      className="flex flex-col items-center justify-center w-full h-[400px]"
    >
      <div className="relative w-[320px] h-[320px]">
        {/* Adding 3D Effect using Shadow */}
        <div className="absolute inset-0  border-none rounded-full shadow-lg"></div>

        <Pie
          data={chartData}
          options={{
            plugins: {
              legend: { display: false }, // Hide default legend
            },
          }}
        />

        {/* Display percentage labels dynamically */}
        <div className="absolute inset-0 flex items-center justify-center flex-wrap">
          {visibleData.map((item, index) => (
            <motion.div
              key={index}
              initial={{ opacity: 0, scale: 0.5 }}
              animate={{ opacity: 1, scale: 1 }}
              transition={{ duration: 0.3, delay: index * 0.5 }}
              className="absolute text-xs font-bold text-black bg-white px-1 rounded-md flex justify-center"
              style={labelPositions[index]}
            >{item.name} <br /> {((item.value / totalValue) * 100).toFixed(1)}%
              {/* {((item.value / totalValue) * 100).toFixed(1)}% */}
            </motion.div>
          ))}
        </div>
      </div>
    </motion.div>
  );
};

export default AnimatedPieChart;
