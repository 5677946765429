import React from "react";
import ShareComponent from "../components/ShareComponent";
import HorizontalBarChart from "../components/HorizontalBarChart";
import FunnelChart from "../components/FunnelChart";

const ChapterTen = () => {
  return (
    <div className="bg-white w-full py-20">
      <section id="final-thoughts" className="max-w-screen-xl mx-auto px-4 md:px-6">
        {/* <h2 className="text-lg text-[#2C2565] font-jakarta italic font-medium">
          Chapter 10
        </h2> */}
        <div className="flex items-center gap-2 max-w-xl ">
          <div className="spinning-circle animate-spin-slow bg-yellow-400 rounded-full w-4 h-6 mb-2"></div>

          <h1 className="text-xl md:text-4xl font-black text-[#2C2565] font-jakarta">
            Final Thoughts
          </h1>
        </div>
        <div>
            <p className="text-[#2C2565] font-jakarta text-base md:text-lg xl:text-xl">The 2024 recruitment data paints a clear picture—Nigeria’s graduate job market is fiercely competitive, with only <span className="font-black">1% of over 200,000 applicants</span> making it to the interview stage.
            {/* <p> Employers are raising the bar, using application tracking software, data-driven hiring, pre-employment tests, and personality tests to filter for top talent. For both recruiters and applicants, adaptability, critical thinking, creativity and skills beyond academics are now non-negotiable.</p> */}
             </p>
            <p className="text-[#2C2565] font-jakarta text-base md:text-lg xl:text-xl">For recruiters, efficiency is key—leveraging automation, structured assessments, and ethical screening will refine the hiring process.</p>
           </div>
        {/* <HorizontalBarChart /> */}
        <FunnelChart />

        <ShareComponent
        sectionId="final-thoughts"
          shareText="For both recruiters and applicants, adaptability, critical
              thinking, creativity and skills beyond academics are now
              non-negotiable."
          shareImage="https://images.unsplash.com/photo-1573496359142-b8d87734a5a2?auto=format&fit=crop&q=80&w=200"
          hrColor="bg-[#2C2565]"
          textColor="text-[#2C2565]"
          buttonColor="bg-[#2C2565]"
          buttonTextColor="text-white"
        />
      </section>
    </div>
  );
};

export default ChapterTen;
