import React from "react";
import BarChart from "../components/BarChart";
import ShareComponent from "../components/ShareComponent";

const data = [
  { label: "Alcohol", value: 99, color: "bg-blue-500" },
  { label: "Gambling", value: 97, color: "bg-rose-500" },
  { label: "Safety", value: 92, color: "bg-purple-500" },
  { label: "sexual", value: 89, color: "bg-orange-600" },
  { label: "Tech", value: 85, color: "bg-yellow-600" },
  { label: "Conformity", value: 74, color: "bg-blue-300" },
  { label: "Drugs", value: 68, color: "bg-green-600" },
  { label: "Money", value: 66, color: "bg-pink-300" },
  {
    label: "Side Hustle",
    value: 59,
    color: "bg-yellow-400",
  },
  // { label: "Holidays and Vacations", value: 69, color: "bg-green-800" },
  // { label: "Debt Repayment", value: 48, color: "bg-lime-400" },
  // { label: "Medical", value: 57, color: "bg-purple-300" },
  // { label: "Wedding Expenses", value: 50, color: "bg-yellow-500" },
];
const ChapterNine = () => {
  return (
    <div className="bg-sectionFive w-full py-20">
      <section
        id="integrity-test"
        className="max-w-screen-xl mx-auto px-4 md:px-6"
      >
        {/* <h2 className="text-lg text-white font-jakarta italic font-medium">
          Chapter Nine
        </h2> */}
        <div className="flex items-center gap-2 max-w-xl ">
          <div className="spinning-circle animate-spin-slow bg-yellow-400 rounded-full w-4 h-6 mb-2"></div>
          <h1 className="text-xl md:text-4xl font-black  text-white font-jakarta">
            Integrity Test
          </h1>
        </div>
        <p className="text-white text-base lg:text-xl font-medium my-6 font-jakarta">
          Money ethics is a red flag as 66% of candidates were open to unethical
          financial practices suggesting a normalisation of unethical behaviours.
        </p>
        <BarChart data={data} />
        <ShareComponent
          sectionId="integrity-test"
          shareText="Integrity tests showed that applicants were open to unethical financial practices, suggesting a normalization of unethical behavior."
          shareImage="https://images.unsplash.com/photo-1573496359142-b8d87734a5a2?auto=format&fit=crop&q=80&w=200"
          hrColor="bg-white"
          textColor="text-white"
          buttonColor="bg-transparent"
          buttonTextColor="text-white"
        />
      </section>
    </div>
  );
};

export default ChapterNine;
