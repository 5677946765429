import React from "react";
import ShareComponent from "../components/ShareComponent";
import CustomBarChart from "./CustomBarChart";
import CountBarChart from "../components/CountBarChart";


const dataCont = [
    { label: "Verbal Reasoning", value: 61, color: "#E47D3E", },
    { label: "Critical Reasoning", value: 37, color:  "#C56333", },
    { label: "Numerical Reasoning", value: 66, color:  "#A04A26" },
  
  
  ];
const ByAssessments = () => {
  return (
    <div className="bg-[#B3DCBC] w-full py-20">
      <section
        id="how-participants-perform-bydegree"
        className="max-w-screen-xl mx-auto px-4 md:px-6"
      >
        {/* <h2 className="text-lg text-[#2C2565] font-jakarta">Chapter Seven</h2> */}
        <div className="flex items-center gap-4 max-w-2xl ">
          <div className="spinning-circle animate-spin-slow bg-[#8EAF55] rounded-full w-4 h-6 mb-2"></div>

          <h1 className="text-xl md:text-4xl font-black text-[#2C2565] font-jakarta">
            How Applicants Perform On Online Assessment: By Test Type
          </h1>
        </div>
        <p className="text-[#2C2565] text-base lg:text-xl font-jakarta !mb-2">
          The results show Nigerian graduates perform best in{" "}
          <span className="font-black">numerical reasoning (66%)</span>, thanks
          to the focus on basic math in schools, and
          <span className="font-black">verbal reasoning (61%)</span>, supported
          by the strong emphasis on English.
        </p>
        <p className="text-[#2C2565] text-base lg:text-xl font-jakarta !mb-2">
          However, <span className="font-black">critical reasoning (37%)</span>{" "}
          lags behind, highlighting the reliance on memorization over
          problem-solving in the education system. This shows a need to teach
          thinking skills alongside facts to prepare graduates for real-world
          challenges.
        </p>

        {/* <CountBarChart data={dataCont} /> */}

        <CustomBarChart data={dataCont} />
        {/* <StackedBarChart /> */}
        {/* <HorizontalBarChartSection /> */}
        <ShareComponent
          sectionId="how-participants-perform-by-test-type"
          shareText="Applicants score the lowest on critical reasoning during 
pre-employment online tests."
          shareImage="https://public-ng.s3.amazonaws.com/Performance-Class-of-Degree.png"
          hrColor="bg-[#2C2565]"
          textColor="text-[#2C2565]"
          buttonColor="bg-[#2C2565]"
          buttonTextColor="text-white"
        />
      </section>
    </div>
  );
};

export default ByAssessments;
