import React, { useRef, useEffect, useState } from "react";
import TestimonialSection from "../components/TestimonialSection";
import degreeImage from "../../../assets/images/report/degree.png";
import CountBarChart from "../components/CountBarChart";

import ShareComponent from "../components/ShareComponent";

const ChapterFour = () => {
  return (
    <div className="bg-[#A5DEEB] w-full py-20">
      <section
        id="class-of-degree"
        className="max-w-screen-xl mx-auto px-4 md:px-6"
      >
        {/* <h2 className="text-lg text-[#2C2565] font-jakarta">Chapter Four</h2> */}
        <div className="flex items-center gap-2 max-w-xl ">
          <div className="spinning-circle animate-spin-slow bg-orange-400 rounded-full w-4 h-6 mb-2"></div>
          <h1 className="text-xl md:text-4xl font-black text-[#2C2565] font-jakarta">
            Who's Applying: By Class Of Degree
          </h1>
        </div>
        {/* <TestimonialSection /> */}

        <div className="flex flex-col  items-center  text-left !m-0">
          <div className="w-full">
            {/* <p className="text-base md:text-lg text-[#2C2565]  font-medium font-jakarta !mb-2">
              
            </p> */}
            <p className="text-[#2C2565] text-base lg:text-xl font-jakarta !mb-2">
              Nearly half (49%) of all applications come from candidates with a
              Second Class Lower (2.2) degree, showcasing a significant talent
              pool often overlooked despite their potential.
            </p>
            <p className="text-[#2C2565] text-base lg:text-xl font-jakarta !m-0">
              Candidates with a Second Class Upper (2.1) degree account for 43%
              of applications, underscoring their competitive appeal for many
              graduate programs. Interestingly, First Class graduates and those
              in other classifications collectively make up just 8%.
            </p>
          </div>
          {/* <div className="w-full h-full"> */}

          {/* </div> */}
        </div>
        <img
          src={degreeImage} // Replace with the actual image path
          alt="degree-img"
          className="w-full h-full my-6 !p-0"
        />

        {/* <div className="hidden md:flex items-center justify-center gap-4 p-5">
          <div className="w-48 h-24 flex items-center justify-center text-white font-bold bg-orange-400 clip-second">
            Second <br /> Class Lower <br /> (49%)
          </div>

          <div className="w-48 h-24 flex items-center justify-center text-white font-bold bg-[#DD4992] clip-second">
            Second <br /> Class Upper <br /> (43%)
          </div>
          <div className="w-48 h-24 flex items-center justify-center text-white font-bold bg-[#4A90BE] clip-other">
            First Class <br /> (4%)
          </div>

          <div className="w-48 h-24 flex items-center justify-center text-white font-bold bg-[#9D2121] clip-other">
            Other <br /> (4%)
          </div>
        </div> */}
        <div className="mb-4 grid grid-cols-2 gap-4 sm:flex sm:flex-wrap sm:justify-center">
    {[
      { label: "Second Class Lower", percentage: "49%", color: "bg-orange-400" },
      { label: "Second Class Upper", percentage: "43%", color: "bg-[#DD4992]" },
      { label: "First Class", percentage: "4%", color: "bg-[#4A90BE]" },
      { label: "Other", percentage: "4%", color: "bg-[#9D2121]" },
    ].map(({ label, percentage, color }, index) => (
      <div key={index} className="flex items-center gap-2">
        <span className={`w-6 h-6 ${color} block flex-none rounded-sm`}></span>
        <span className="text-xs md:text-sm font-medium text-[#2C2565]">
          {label} ({percentage})
        </span>
      </div>
    ))}
  </div>

        {/* <div className="flex flex-col  items-center gap-6 text-left">
          <div className="w-full ">
            <p className="text-sm md:text-lg text-gray-700 font-jakarta">
              By Age
            </p>
            <p className="text-lg text-white md:text-2xl font-bold  mt-2 font-jakarta">
              Nearly half (49%) of all applications come from candidates with a
              Second Class Lower (2.2) degree, showcasing a significant talent
              pool often overlooked despite their potential.
            </p>
          </div>
          <div className="relative w-full ">
            <CountBarChart data={dataCont} />
          </div>
        </div> */}

        <ShareComponent
          sectionId="class-of-degree"
          shareText="Despite making up nearly half of applications, candidates with a
              2:2 degree are often overlooked."
          shareImage="https://public-ng.s3.amazonaws.com/By-Class-of-Degree.png"
          hrColor="bg-[#6B4AE8]"
          textColor="text-[#2C2565]"
          buttonColor="bg-orange-400"
          buttonTextColor="text-white"
        />
      </section>
    </div>
  );
};

export default ChapterFour;
