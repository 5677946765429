import { Field, Form, Formik } from 'formik'
import React, {useEffect} from 'react'
import { IRegisterPayload, useRegistration, useGetDiscountCode, useCheckDiscountCode } from '../../../hooks/landingpage/useLandingPage'
import { toast } from 'react-toastify'
import Swal from 'sweetalert2'
import { values } from 'cypress/types/lodash'
import { useNavigate } from 'react-router-dom'

interface NewsletterPayload{
    formDetails:IRegisterPayload, setFormDetails:any
}
function Newsletter({formDetails, setFormDetails}:NewsletterPayload) {
   
   const navigate= useNavigate()
   const [discountCode, setDiscountCode]= React.useState("")

    const registerAPI= useRegistration()
    const getDiscountCode = useGetDiscountCode()
    const [discount, setDiscount]= React.useState<any>(null)
    const {mutateAsync} = useCheckDiscountCode(getDiscountCode.data?.data[0]?.id,)

    const getDiscount = async () => {
        try {
            const response = await mutateAsync({
                platform: 'tap',
                discountCode,
            })
            setDiscount(response?.data)
        } catch (error: any) {
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: error?.response?.data?.message,
                showCloseButton: true,
                confirmButtonColor:"#cc0000"
            });
        }
    }
    
    const getPercentOff = () : (number | undefined) => {
        if(discount){
            const discountAmount = parseFloat(discount.discountAmount);
            return ((discountAmount / discount.oldAmount) * 100)
        }
        return
    }

  return (
   <section className='p-6 flex flex-col items-center'>
<div className="flex flex-col md:flex-row mb-8 items-center gap-4 lg:gap-8">
    <h3 className="md:flex-0.5 mb-4 text-4xl max-w-lg font-semibold text-black dark:text-white "> Sign Up Now for the Upcoming Online Job Fair!</h3>
    <p className='text-lg w-full max-w-lg md:flex-0.5 text-black dark:text-white '>Unlock your potential with test preparations, tailored courses, expert-led webinars, and job fairs and a thriving community. Take the first step toward landing your dream job!</p>
</div>

<div id='registration-form' className="w-full max-w-2xl mb-8 bg-[#8CAF4B] dark:border dark:border-slate-200 dark:bg-transparent rounded-2xl p-4 md:p-8 text-black">
 { 
    <Formik 
        initialValues={{...formDetails,  discountCode: '',}}
        enableReinitialize
        onSubmit={(values, {resetForm, setSubmitting} )=>{
            //@ts-expect-error
            window.gtag('event', 'form_submission', {
                'event_category': 'form_start',
                'event_label': 'My Label',
            });

            registerAPI.mutate(({
                ...values as IRegisterPayload,
                platformSegmentId: "463", 
                platform: "tap",  
                emailVerificationUrl: `${window.location.origin}/tap/verify-email`,
                payment: {
                    productId: getDiscountCode.data?.data[0]?.id,
                    amount: discount?.amount || getDiscountCode.data?.data[0]?.amount,
                    actualAmount: getDiscountCode.data?.data[0]?.amount,
                    discountCode: discountCode,
                    discountAmount: discount?.discountAmount,
                    callBackUrl: `${window.location.origin}/tap/thank-you`,  
                } ,
            }), {
            onSuccess(data){
                    resetForm()
                    setFormDetails({
                        email:"",name:"", phoneNumber:"",
                    })
                    sessionStorage.clear()
                    toast.dismiss()
                    // navigate("/tap/thank-you")
                    window.open(data?.data?.paymentUrl, "_blank")
            },
                    onError(err:any){
                    toast.dismiss()
                    toast.error(err?.response?.data?.message)
                        setSubmitting(false)
                        }
                    })
          }}
    >
{ 
    ({isSubmitting, values})=><Form>
        <p className='font-semibold mb-2 text-3xl text-white '>Sign Up Now for the TAP program!</p>
        <p className='text-white '>Fill your personal details to continue the sign up</p>

        <div className=" flex justify-between gap-4 mt-8">
            <div className="w-full">
                <p  className='text-sm text-white  font-semibold mb-1'>Full Name <span className='text-red-500'>*</span></p>
                <Field  id="name" name="name" required className="w-full input input-bordered dark:bg-white md:input-sm "/>
            </div>
            {/* <div className="w-full">
                <p className='text-sm text-white  font-semibold mb-1'>Last Name <span className='text-red-500'>*</span></p>
                <Field name="last_name"  required className="w-full input input-bordered md:input-sm dark:bg-white"/>
            </div> */}
        </div>

        <div className="w-full mt-4">
                <p className='text-sm text-white  font-semibold  mb-1'>Email <span className='text-red-500'>*</span></p>
                <Field name="email" required className="w-full input input-bordered md:input-sm dark:bg-white dark:text-white "/>
            </div>
        <div className="w-full mt-4">
                <p className='text-sm text-white  font-semibold  mb-1'>Phone Number <span className='text-red-500'>*</span></p>
                <Field name="phoneNumber"  placeholder="+2348012345678" required className="w-full input input-bordered md:input-sm dark:bg-white"/>
            </div>

        <section className='flex items-end'>
            <div className="w-full mt-4"> 
                <p className='text-sm text-white  font-semibold  mb-1'>
                  Current Price {
                  !discount ?    
                 <span>
                  (&#8358;{
                    getDiscountCode.isLoading ? "Loading" : getDiscountCode.data?.data[0]?.amount
                  })
                  </span>
                  : <span>
                  <span 
                    style={{ textDecoration: 'line-through' }}
                    className='text-gray-200'
                  >(
                    &#8358;{
                    getDiscountCode.isLoading ? "Loading" : getDiscountCode.data?.data[0]?.amount
                  } )
                  </span>
                    &nbsp; &#8358;{
                    discount?.amount
                   } &nbsp;&nbsp; {getPercentOff()}% off
                  </span>}
                </p>
                <input 
                 name="discountCode"  
                 value={discountCode}
                 onChange={(e: React.ChangeEvent<HTMLInputElement>)=>setDiscountCode(e.target.value)}
                 placeholder="Enter your discount code" 
                 className="w-full input input-bordered md:input-sm dark:bg-white"
                />
            </div>
            <button 
              type='button' className="btn ml-5 btn-lg px-16  md:btn-sm dark:bg-[#8CAF4B] dark:text-white "
              onClick={getDiscount}
            >
              Get Discount
            </button>
        </section>
        {/* <div className="w-full mt-4">
                <p className='text-sm text-black font-semibold  mb-1'>How did you hear about us?</p>
                <Field as="select" name="referral"  className="w-full select select-bordered md:input-sm dark:bg-white">
<option value="" disabled>Select</option>
<option value="Google Search">Google Search</option>
<option value="Linkedin">Linkedin</option>
<option value="Instagram">Instagram</option>
<option value="Facebook">Facebook</option>
<option value="Twitter">Twitter</option>
<option value="Email Newsletter">Email Newsletter</option>
<option value="Sms">Sms</option>
<option value="Word of Mouth / Referred by a friend">Word of Mouth / Referred by a friend</option>
<option value="Online Ad">Online Ad</option>
                    </Field>
            </div> */}
        {/* <div className="w-full mt-4">
                <p className='text-sm text-black font-semibold  mb-1'>Linkedin Url</p>
                <Field name="social_url" className="w-full input input-bordered md:input-sm dark:bg-white"/>
            </div> */}

        <div className="">
            <button disabled={isSubmitting} className="btn btn-lg px-16  md:btn-sm mt-8 dark:bg-[#8CAF4B] dark:text-white ">Submit</button>
        </div>
    </Form>
}
    </Formik>}
</div>

   </section>
  )
}

export default Newsletter
