import React from "react";
import CountBarChart from "../components/CountBarChart";
import ShareComponent from "../components/ShareComponent";

const dataCont = [
  { label: "Age", value: 67, color: "#008CFF" },
  { label: "University", value: 27, color: "#E63976" },
  { label: " Degree", value: 18, color: "#662D91" },
  { label: "NYSC", value: 15, color: "#FFD700" },
  // { label: "Housing and Rent", value: 16, color: "#00091A" },
  // { label: "Personal Education", value: 11, color: "#F7931A" },
  // { label: "Religious Obligations", value: 3, color: "#C0C0C0" },
  // { label: "Transport Costs", value: 42, color: "#A2FF59" },
  // { label: "Utility Bills", value: 35, color: "#FFB6E1" },
];
const ChapterSix = () => {
  return (
    <div className="bg-[#0C4354] w-full py-20">
      <section
        id="top-reason-for-disqualification"
        className="max-w-screen-xl mx-auto px-4 md:px-6"
      >
        {/* <h2 className="text-lg text-white font-jakarta">Chapter Six</h2> */}
        <div className="flex items-center gap-2 w-full md:max-w-xl ">
          <div className="spinning-circle animate-spin-slow bg-yellow-400 rounded-full w-4 h-6 mb-2"></div>

          <h1 className="text-xl md:text-4xl font-black text-white font-jakarta mb-6 ">
            Top Reason For Disqualification
          </h1>
        </div>
        <div>
          <p className="text-white text-base md:text-lg font-jakarta font-medium  !mb-10">
            This high rate of age-related ineligibility is deeply concerning,
            particularly given the systemic delays Nigerian students often
            face—such as academic strikes, economic barriers, or infrastructural
            gaps—that prolong their education timelines through no fault of
            their own.
          </p>
        </div>
        <CountBarChart data={dataCont} />
        <ShareComponent
          sectionId="top-reason-for-disqualification"
          shareText="67% of applicants are disqualified due to age 
requirements"
          shareImage="https://public-ng.s3.amazonaws.com/Top-Reason-for-Disqualification.png"
          hrColor="bg-[#6B4AE8]"
          textColor="text-white"
          buttonColor="bg-transparent"
          buttonTextColor="text-white"
        />
      </section>
    </div>
  );
};

export default ChapterSix;
