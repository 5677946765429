import React from "react";
import { motion } from "framer-motion";
import { useInView } from "react-intersection-observer";

const BarChart = ({ data }) => {
  const { ref, inView } = useInView({ triggerOnce: true, threshold: 0.3 });

  return (
    <div ref={ref} className="text-white">
      <div className="relative w-full">
        {/* Vertical Divider Line */}
        <div className="absolute left-24 top-0 bottom-0 w-[2px] bg-white"></div>

        {data.map((item, index) => (
          <BarItem key={index} item={item} index={index} inView={inView} />
        ))}
      </div>
    </div>
  );
};

const BarItem = ({ item, index, inView }) => {
  return (
    <motion.div
      className="flex items-center gap-x-10 " // No extra margin between items
      initial={{ opacity: 0, x: -50 }}
      animate={inView ? { opacity: 1, x: 0 } : {}}
      transition={{ delay: index * 0.2, duration: 0.5 }}
    >
      {/* Label */}
      <p className="text-sm md:text-lg w-20 font-medium text-left pr-4 text-white">
        {item.label}
      </p>

      {/* Bar with Percentage Inside */}
      <div className="flex-1 relative h-6">
        <motion.div
          className={`h-full ${item.color} rounded-r-lg flex items-center justify-end pr-2`}
          initial={{ width: "0%" }}
          animate={inView ? { width: `${item.value}%` } : {}}
          transition={{ delay: index * 0.2 + 0.3, duration: 0.8 }}
        >
          {/* Percentage Label inside Bar */}
          <motion.p
            className="text-xs font-bold bg-white text-black px-1 mt-3 rounded-md shadow"
            initial={{ opacity: 0, scale: 0.8 }}
            animate={inView ? { opacity: 1, scale: 1 } : {}}
            transition={{ delay: index * 0.4, duration: 0.5 }}
          >
            {item.value}%
          </motion.p>
        </motion.div>
      </div>
    </motion.div>
  );
};

export default BarChart;
