import React from "react";
import AnimatedPieChart from "../components/AnimatedPieChart";
import RecruitmentStats from "../components/RecruitmentStats";
import ShareComponent from "../components/ShareComponent";

const sampleData = [
  { name: "React", value: 45 },
  { name: "Vue", value: 25 },
  // { name: "Angular", value: 20 },
  // { name: "Svelte", value: 10 },
];

const colors = ["#0088FE", "#00C49F", "#FFBB28", "#FF8042"];

const ChapterTwo = () => {
  return (
    <div id="executive-summary" className="bg-[#B3DCBC] w-full py-20">
      <section className="max-w-screen-xl mx-auto px-4 md:px-6">
        {/* <h2 className="text-lg text-white font-jakarta italic font-medium">
          Chapter Two
        </h2> */}

        <div className="flex items-center gap-2 max-w-xl ">
          <div className="spinning-circle animate-spin-slow bg-[#F08B75] rounded-full w-4 h-6 mb-2"></div>

          <h1 className="text-xl md:text-4xl font-black text-white font-jakarta">
            Executive Summary
          </h1>
        </div>
        <div>
          <h1 className="text-xl font-bold text-white font-jakarta mb-5">
            The Reality of Graduate Recruitment
          </h1>
        </div>
        <div className="flex flex-col gap-y-5 font-medium">
          {/* <p className="text-white text-sm md:text-base lg:text-xl font-jakarta">
            Hiring the best is like finding a needle in a haystack, this is the
            reality many recruiters face. With only 1% of applicants making it
            to the interview stage, filtering top talent is considered an
            arduous task.
          </p> */}
          <p className="text-white text-base lg:text-xl font-jakarta">
            Our findings, based on 200,000+ graduate applications, review some
            patterns with regards to application process, applicant eligibility,
            and performance. The findings challenge some common hiring
            assumptions and highlight the gaps between employer expectations and
            applicant readiness.
          </p>
          {/* <p className="text-white text-sm md:text-base lg:text-xl font-jakarta">
            For graduate positions, most employers are looking for young
            graduates (less than 28) with basic skills such as:
          </p> */}
          {/* <ul className="text-white text-sm md:text-base lg:text-xl font-jakarta list-disc list-inside">
            <li>Communication</li>
            <li>Problem solving</li>
            <li>Analytical skill</li>
            <li>Team work & collaboration</li>
          </ul> */}
        </div>

        {/* <div className="flex mt-10">
          <div className="w-1/2">
            <div class="relative z-20 flex flex-col">
              
              <p class="text-sm  sm:text-base text-white font-jakarta">
                Adaora*, a 23-year-old salary earner based in Enugu:
              </p>
              <h1 class="md:max-w-xs mt-6 flex font-jakarta flex-col text-xl font-bold leading-none text-[#0089FF] font-bebas-neue sm:text-3xl dark:text-white">
                “It’s like peanuts these days, earning ₦100k.”
              </h1>
            </div>
          </div>
          <div className="w-1/2">
            {" "}
            <AnimatedPieChart data={sampleData} colors={colors} />
          </div>
        </div> */}
        <RecruitmentStats />
        <ShareComponent
          sectionId="executive-summary"
          shareText="Only about 1% of applicants make it to interviews."
          shareImage="https://images.unsplash.com/photo-1573496359142-b8d87734a5a2?auto=format&fit=crop&q=80&w=200"
          hrColor="bg-gray-900"
          textColor="text-black"
          buttonColor="bg-[#F08B75]"
          buttonTextColor="text-white"
        />
      </section>
    </div>
  );
};

export default ChapterTwo;
