import React, { useRef, useEffect, useState } from "react";
import BarChart from "../components/BarChart";
import PieChart from "../components/PieChart";
import CountBarChart from "../components/CountBarChart";
import ShareComponent from "../components/ShareComponent";
import moneyImage from "../../../assets/images/report/money.png";

const sampleData = [
  { label: "Yes", value: 16, color: "#e63946" }, // Red
  { label: "No", value: 84, color: "#6a4ec7" }, // Purple
];

const data = [
  { label: "Building emergency funds", value: 90, color: "bg-blue-500" },
  { label: "Starting/Growing a Business", value: 81, color: "bg-rose-500" },
  { label: "Child(ren) upkeep", value: 74, color: "bg-purple-500" },
  { label: "Rent/Housing", value: 63, color: "bg-orange-600" },
  { label: "New personal belongings", value: 73, color: "bg-yellow-600" },
  { label: "Personal education", value: 21, color: "bg-blue-300" },
  { label: "Buying a car", value: 10, color: "bg-green-600" },
  { label: "Emigration [Japa]", value: 80, color: "bg-pink-300" },
  {
    label: "Special Events and Celebrations",
    value: 10,
    color: "bg-yellow-400",
  },
  { label: "Holidays and Vacations", value: 69, color: "bg-green-800" },
  { label: "Debt Repayment", value: 48, color: "bg-lime-400" },
  { label: "Medical", value: 57, color: "bg-purple-300" },
  { label: "Wedding Expenses", value: 50, color: "bg-yellow-500" },
];

const dataCont = [
  { label: "Under 21 years", value: 11, color: "bg-blue-500" },
  { label: "21-23 years", value: 26, color: "bg-pink-500" },
  { label: "24-26 years", value: 28, color: "bg-green-500" },
  { label: "26years+", value: 35, color: "bg-blue-300" },
];

const ChapterFive = () => {
  const [animateIndex, setAnimateIndex] = useState(-1);
  const sectionRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          dataCont.forEach((_, index) => {
            setTimeout(() => setAnimateIndex(index), index * 300);
          });
        }
      });
    });

    if (sectionRef.current) {
      observer.observe(sectionRef.current);
    }

    return () => {
      if (sectionRef.current) {
        observer.unobserve(sectionRef.current);
      }
    };
  }, []);
  return (
    <div ref={sectionRef} className="bg-[#6B4AE8] text-white w-full py-20">
      <section
        id="age"
        className="max-w-screen-xl mx-auto px-4 md:px-6"
      >
        {/* <h2 className="text-lg text-white font-jakarta">Chapter Five</h2> */}
        <div className="flex items-center gap-2 max-w-xl ">
          <div className="spinning-circle animate-spin-slow bg-yellow-400 rounded-full w-4 h-6 mb-2"></div>

          <h1 className="text-xl md:text-4xl font-black text-white font-jakarta">
            Who's Applying: By Age
          </h1>
        </div>

        <div className="flex flex-col  items-center gap-6 text-left mt-5 md:mt-10">
          <div className="w-full">
            {/* <p className="text-sm md:text-lg text-white font-jakarta">By Age</p> */}
            <p className="text-white text-base lg:text-xl font-jakarta">
              A notable 26%of applications come from those who are between 21-23
              years old, showing a youthful drive to enter higher education
              early, especially among high performing students or those from
              accelerated academic tracks.
            </p>
            <p className="text-white text-base lg:text-xl font-jakarta">
              The highest percentage of applications comes from those above 
              <span className="font-black"> 26 years old</span>, suggesting that
              many Nigerians are graduating much later possibility due to delays
              in securing admissions, systematic delays or financial challenges.
            </p>
          </div>
          <div className="relative w-full mb-10 ">
            <div>
              {/* Content */}
              <div className="max-w-screen-lg mx-auto  relative">
                {/* Background Image */}

                <div
                  className="absolute inset-0 opacity-100 bg-cover bg-center bg-no-repeat w-full"
                  // style={{ backgroundImage: `url(${moneyImage})` }}
                ></div>
                {/* Bar Chart */}
                {/* Background Image */}
                <div
                  className="absolute inset-0 opacity-50 bg-cover bg-no-repeat"
                  // style={{ backgroundImage: `url(${moneyImage})` }}
                ></div>

                {/* Bar Chart */}
                <div className="relative flex items-end justify-center mt-8 h-fit">
                  {dataCont.map((data, index) => (
                    <div
                      key={index}
                      className="relative flex flex-col items-center flex-1 group"
                    >
                      {/* Bar */}
                      <div
                        className={`${data.color} opacity-70 group-hover:opacity-100 transition-all duration-600 w-full flex items-end justify-center`}
                        style={{
                          height:
                            animateIndex >= index
                              ? `${data.value * 2.5}px`
                              : "0px",
                          transition: "height 0.8s ease-in-out",
                        }}
                      >
                        {/* Percentage Inside Bar */}
                        <span className="text-white text-base md:text-2xl  font-bold  opacity-100">
                          {data.value}%
                        </span>
                      </div>
                    </div>
                  ))}
                </div>
                {/* Labels */}
              </div>
              <div className="relative z-10 flex justify-between mt-4 md:px-20">
                {dataCont.map((data, index) => (
                  <p key={index} className="text-sm text-white font-jakarta">
                    {data.label}
                  </p>
                ))}
              </div>
            </div>
          </div>
        </div>
        {/* <BarChart data={data} />
        <PieChart data={sampleData} />
        <CountBarChart data={dataCont} /> */}

        <ShareComponent
          sectionId="age"
          shareText="Applicants over 26 years old account for 35% of the 
applicant pool."
          shareImage="https://public-ng.s3.amazonaws.com/By-Age.png"
          hrColor="bg-white"
          textColor="text-white"
          buttonColor="bg-transparent"
          buttonTextColor="text-white"
        />
      </section>
    </div>
  );
};

export default ChapterFive;
