import { AxiosInstance } from "axios";
import { createApiClient } from "./apiClient";


const client = createApiClient()

export const report = {
  shareReport: ( payload) =>
    client
      .post(`/reports/send-download-link`, payload )
      .then(({ data }) => data),
  trackReport: ({ payload}) =>
    client
      .post(`/reports/share/track`, payload )
      .then(({ data }) => data),
}
