import { Col, Row, Container } from "react-bootstrap";
import { Link } from "react-router-dom";
import Logo from "../components/Logo";
import {RiArrowDropRightLine} from 'react-icons/ri';
import Ads from "../components/Ads/Ads";

const Footer = () => {
  return (
      <>
      <Ads />
    <footer style={footer}>
      <Container>
        <Row>
          <Col sm={12} md={5}>
            <Logo height={50} /> <br />
            <div className="mt-2" style={{ paddingLeft: "1.5rem" }}>
              <small>
                © TestAssessify 2022. All rights reserved. <br />
                Registered office is at 35, Ladipo Labinjo Crescent, Surulere.{" "}
                <br />
              </small>
            </div>
          </Col>
          <Col sm={12} md={3} className="mt-3">
            <ul>
              <li>
                <Link className="text-gray-900 text-[15px] dark:text-slate-900 hover:text-armyGreen items-center flex" to="/about-us">
                  About Us <RiArrowDropRightLine size={30}/>
                </Link>
              </li>
              <li>
                <Link className="text-gray-900 text-[15px]  dark:text-slate-900 hover:text-armyGreen items-center flex" to="/for-companies">
                    For Companies<RiArrowDropRightLine size={30}/>
                </Link>
              </li>
              <li>
                <Link className="text-gray-900 text-[15px]  dark:text-slate-900 hover:text-armyGreen items-center flex" to="/job-listings">
                   Find Jobs<RiArrowDropRightLine size={30}/>
                 </Link>
                 <Link className="text-gray-900 text-[15px]  dark:text-slate-900 hover:text-armyGreen items-center flex" to="/frequently-asked-questions">
                    FAQs <RiArrowDropRightLine size={30}/>
                 </Link>
              </li>
            </ul>
          </Col>
          <Col sm={1} md={3}>
            <h5 className="text-lg text-gray-950"> Connect With Us On:</h5>
            <div style={iconStyles} className="">
              <div className="-mt-8 mr-6">
                 <span className="bi bi-linkedin text-2xl text-blue-500"></span>
              </div>               
              <div className="-mt-8 mr-6">
                 <span className="bi bi-twitter text-2xl text-blue-500"></span>
              </div>
              <div className="-mt-8 mr-6">
                 <span className="bi bi-instagram text-2xl text-[brown]"></span>
              </div>   
              <div>
              </div>
              <div>
              </div>
            </div>
            <p className="text-[16px] text-gray-950 w-full mt-2">
                <span className="bi bi-telephone"></span> +234 702 658 5852
            </p>
          </Col>
        </Row>
      </Container>
    </footer>
    </>
  );
};

export default Footer;

const footer = {
  backgroundColor: "rgb(249,250,249)",
  color: "black",
  fontSize: "18px",
  padding: "50px 0",
};
const iconStyles = {
  display: "flex",
  justifyContent: "space-between",
  marginTop: "4.5rem"
};