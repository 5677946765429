import React from "react";
import { motion } from "framer-motion";
import { useInView } from "react-intersection-observer";
import { FiCheckCircle } from "react-icons/fi";
import { FaArrowDownLong } from "react-icons/fa6";
import girlImage from "../../../assets/images/report/09.png";
import guyImage from "../../../assets/images/report/10.png";
const GraduateRecruitment = () => {
  const [ref, inView] = useInView({
    triggerOnce: true,
    threshold: 0.1,
  });

  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.3,
      },
    },
  };

  const logoVariants = {
    hidden: { opacity: 0, y: -20 },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.6,
        ease: "easeOut",
      },
    },
  };

  const imageVariants = {
    hidden: { opacity: 0, scale: 0.8, rotate: -5 },
    visible: (custom) => ({
      opacity: 1,
      scale: 1,
      rotate: 0,
      transition: {
        delay: custom * 0.3,
        duration: 0.8,
        type: "spring",
        stiffness: 100,
      },
    }),
  };

  const textVariants = {
    hidden: { opacity: 0, y: 30 },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        delay: 0.6,
        duration: 0.8,
        ease: "easeOut",
      },
    },
  };

  const numberVariants = {
    hidden: { opacity: 0, scale: 0.5 },
    visible: {
      opacity: 1,
      scale: 1,
      transition: {
        delay: 0.8,
        duration: 1,
        type: "spring",
        stiffness: 80,
      },
    },
  };

  const text = [
    "This report, grounded in the analysis of over 200,000 applications and online assessments conducted in 2024, provides a comprehensive, evidence-based perspective on the trends, challenges, and opportunities shaping recruitment in Nigeria today.",
  ];

  const scrollToNextSection = () => {
    const nextSection = document.getElementById("next-section");
    if (nextSection) {
      nextSection.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <motion.div
      ref={ref}
      className="w-full mt-5  bg-[#8BC6A6]  overflow-hidden relative"
      variants={containerVariants}
      initial="hidden"
      animate={inView ? "visible" : "hidden"}
    >
      <div className="">
        <div className="absolute inset-0 opacity-10 overflow-hidden">
          <div className="text-white text-[80px] md:text-[100px] font-bold leading-[1.1] tracking-wide">
            {Array(9)
              .fill("TestAssessify")
              .map((text, index) => (
                <div key={index} className="whitespace-nowrap">
                  {text}
                </div>
              ))}
          </div>
        </div>

       
      </div>
      {/* Background pattern */}
      <div>
      <div className="relative z-10 p-4 md:p-8   ">
          {/* Logo */}
          {/* <motion.div
            className="flex items-center mb-12"
            variants={logoVariants}
          >
            <FiCheckCircle className="w-10 h-10 text-white mr-2" />
            <span className="text-white text-3xl font-bold">TestAssessify</span>
          </motion.div> */}

          {/* Images */}
          <div className="relative h-[600px] md:h-[600px] 2xl:h-[400px]">
            <motion.div
              custom={0}
              variants={imageVariants}
              className="absolute rotate-6 right-0 top-32 md:top-0 p-4 w-[280px] md:w-[320px]  rounded-3xl overflow-hidden drop-shadow-[10px_10px_4px_#4A92C0]"
              style={{
                backgroundColor: "#b3ddf5",
                transform: "rotate(-5deg)",
              }}
            >
              <img
                src={girlImage}
                  // src="https://images.unsplash.com/photo-1531123897727-8f129e1688ce?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=774&q=80"
                alt="Female graduate"
                className="w-full h-[300px] md:h-96 object-cover rounded-3xl"
              />
            </motion.div>

            <motion.div
              custom={1}
              variants={imageVariants}
              className="absolute right-0 -rotate-6 top-[480px]  flex justify-center  md:top-[480px] p-2 w-[280px] md:w-[320px] rounded-3xl overflow-hidden drop-shadow-[10px_10px_4px_#4A92C0]"
              style={{
                backgroundColor: "#b3ddf5",
                transform: "rotate(-5deg)",
              }}
            >
              <img
              // src="https://images.unsplash.com/photo-1531123897727-8f129e1688ce?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=774&q=80"
                src={guyImage}
                  // src="https://images.unsplash.com/photo-1544717305-2782549b5136?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=774&q=80"
                alt="Male graduate"
                className="w-full  h-[300px] md:h-96 object-cover object-top"
              />
            </motion.div>
          </div>

          {/* Bottom text and number */}
          <div className="mb-5 flex justify-center lg:justify-end items-center gap-x-5 md:gap-x-0 pt-60 md:pt-96 2xl:pt-[35rem]">
            <motion.div variants={textVariants} className="md:mr-4">
              <h1 className="text-white text-3xl  lg:text-5xl font-black font-poppins space-y-2 leading-tight mt-5 md:mt-10">
                Graduate
                <br />
                Recruitment
                <br />
                Snapshot
              </h1>
            </motion.div>

            <motion.div
              variants={numberVariants}
              className="text-white text-[80px] md:text-[200px] lg:text-[300px] font-light leading-none"
            >
              '25
            </motion.div>
          </div>

          {/* Move text and scroll button to the bottom */}
          <div className="text-white text-sm md:text-xl max-w-5xl">
            {text.map((sentence, index) => (
              <motion.p
                key={index}
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ duration: 0.5, delay: index * 1 }}
                className="text-[#2C2565] text-base md:text-lg font-medium font-jakarta"
              >
                {sentence}
              </motion.p>
            ))}
            {/* <motion.span
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 0.5, delay: text.length * 1 }}
              onClick={scrollToNextSection}
              className="cursor-pointer rounded-full bg-white w-fit px-4 py-2 font-jakarta text-[#2C2565] hover:underline flex items-center gap-x-2 font-medium mt-10"
            >
              Scroll
              <span>
                <FaArrowDownLong />
              </span>
            </motion.span> */}
          </div>
        </div>
        
      </div>
    </motion.div>
  );
};

export default GraduateRecruitment;
