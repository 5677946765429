import React from "react";
import ShareComponent from "../components/ShareComponent";
import degreeImage from "../../../assets/images/report/12.png";

const ApplicantsPipeline = () => {
  return (
    <div className="bg-[#B3DCBC] w-full py-20">
      <section
        id="how-participants-perform-bydegree"
        className="max-w-screen-xl mx-auto px-4 md:px-6"
      >
        {/* <h2 className="text-lg text-[#2C2565] font-jakarta">Chapter Seven</h2> */}
        <div className="flex items-center gap-4 ">
          <div className="spinning-circle animate-spin-slow bg-[#8EAF55] rounded-full w-4 h-6 mb-2"></div>

          <h1 className="text-xl md:text-4xl  font-black text-[#2C2565] font-jakarta">
            Impact Of Additional Stages On Applicants Pipeline
          </h1>
        </div>
        <p className="text-[#2C2565] text-base lg:text-lg font-jakarta !mb-6">
          As we move from one stage of the recruitment cycle to the next,
          applicants drop off the funnel. Generally, an average of{" "}
          <span className="font-black">22-27%</span> of applicants drop off with
          each additional recruitment level.
        </p>

        <div className="my-6 w-full h-full flex justify-center">
        <img
          src={degreeImage} // Replace with the actual image path
          alt="degree-img"
          className="h-full w-full"
        />
        </div>
        <ShareComponent
          sectionId="how-participants-perform-by-test-type"
          shareText="Generally, an average of 22-27% of applicants drop off as 
you move from one recruitment stage to another."
          shareImage="https://public-ng.s3.amazonaws.com/Performance-Class-of-Degree.png"
          hrColor="bg-[#2C2565]"
          textColor="text-[#2C2565]"
          buttonColor="bg-[#2C2565]"
          buttonTextColor="text-white"
        />
      </section>
    </div>
  );
};

export default ApplicantsPipeline;
