import React from "react";
import BarChartSection from "../components/BarchartSection";
import HorizontalBarChartSection from "../components/HorizontalBarChartSection";
import ApplicationStats from "../components/ApplicationStats";
import ShareComponent from "../components/ShareComponent";

const ChapterThree = () => {
  return (
    <div className="bg-white w-full py-20">
      <section id="key-findings" className="max-w-screen-xl mx-auto px-4 md:px-6">
        {/* <h2 className="text-lg text-[#6B4AE8] font-jakarta italic font-medium">
          Chapter Three
        </h2> */}
        <div className="flex items-center gap-2 max-w-xl ">
          <div className="spinning-circle animate-spin-slow bg-yellow-400 rounded-full w-4 h-6 mb-2"></div>
          <h1 className="text-xl md:text-4xl font-black text-[#6B4AE8] font-jakarta">
            Key Findings 
          </h1>
        </div>
        {/* <BarChartSection /> */}
        {/* <HorizontalBarChartSection /> */}
        <ApplicationStats />
        <ShareComponent
        sectionId="key-findings"
          shareText="Age is the biggest disqualifier for Graduate Entry Roles."
          shareImage="https://images.unsplash.com/photo-1573496359142-b8d87734a5a2?auto=format&fit=crop&q=80&w=200"
          hrColor="bg-[#6B4AE8]"
          textColor="text-[#6B4AE8]"
          buttonColor="bg-[#6B4AE8]"
          buttonTextColor="text-white"
        />
      </section>
    </div>
  );
};

export default ChapterThree;
