import React from "react";
import IncomeChart from "../components/IncomeChart";
import ShareComponent from "../components/ShareComponent";

const ChapterOne = () => {
  return (
    <div id="foreword" className="max-w-screen-xl mx-auto py-20 px-4 md:px-6">
      <section id="next-section" className="">
        <IncomeChart />
      </section>
      <ShareComponent
        sectionId="foreword"
        shareText="For both recruiters and applicants, adaptability, critical thinking, creativity, and skills beyond academics are now non-negotiable."
        shareImage="https://images.unsplash.com/photo-1573496359142-b8d87734a5a2?auto=format&fit=crop&q=80&w=200"
        hrColor="bg-white"
        textColor="text-white"
        buttonColor="bg-white"
        buttonTextColor="text-[#060F21]"
      />
    </div>
  );
};

export default ChapterOne;
