import React, { useEffect, useLayoutEffect, useState } from 'react'
import Hero from './components/Hero'
import Testimonial from './components/Testimonial'
import Newsletter from './components/Newsletter'
import Footer from './components/Footer'
import Header from './components/Header'
import { useSearchParams } from 'react-router-dom'
import { Events, animateScroll as scroll, scrollSpy } from 'react-scroll';
import Ads from '../../components/Ads/Ads'
import { useTrackVisitors } from '../../hooks/landingpage/useLandingPage'



function TAG() {
  const [searchParams]= useSearchParams()
  const actionType= searchParams.get('action')
  const {mutate} = useTrackVisitors()
  const [formDetails, setFormDetails]= useState({
 
  name: sessionStorage.getItem("name") || "",
 //last_name: sessionStorage.getItem("last_name") || "",
 email: sessionStorage.getItem("email") || "",
 //email_verified_at: sessionStorage.getItem("email_verified_at") || "",
 phoneNumber: sessionStorage.getItem("phone_number") || "",
 //registration:""
  })
  
  useEffect(()=>{
    if(actionType==='signup'){
      const name=   document.getElementById("name")
      Events.scrollEvent.register('begin', (to, element) => {
        console.log('begin', to, element);
      });
      scrollSpy.update();
      scroll.scrollTo(name?.offsetTop||0)
    }
    mutate({
      platform:"tap",
      pageUrl:window.location.href
    })
  }, [])

  return (
  <div className="tap">     
    <div className="">
    <div className='tap-header'
      style={{
        // background:" linear-gradient(to right, rgba(140, 175, 75, 0.27) 0%, rgba(140, 175, 75, 0.10)  100%)" ,
        // background:"url(../../assets/images/hero-bg.png)"
      // background:`url(${bgImage})`,
      // backgroundSize:"100% 100%",
      // backgroundRepeat:"no-repeat",
      // backgroundPosition:"center"
      }}
   
     >

       <Header/>
      <Hero setFormDetails={setFormDetails}/>
      </div>
      <div className="h-1 w-full bg-primary"></div>
      {/* <Testimonial/> */}
      <div  className='tap-newsletter' style={{
        // background:" linear-gradient(to right, rgba(140, 175, 75, 0.27) 0%, rgba(140, 175, 75, 0.10)  100%)" ,
        // background:"url(../../assets/images/hero-bg.png)"
      // background:`url(${formImage})`,
      backgroundSize:"100% 100%",
      backgroundRepeat:"no-repeat",
      backgroundPosition:"center"
      }}>

      <Newsletter formDetails={formDetails} setFormDetails={setFormDetails}/>
      </div>
     
      <Footer/>
    </div>
  </div>
  )
}

export default TAG
