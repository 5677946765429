import React, { useState, useEffect } from "react";
import { motion } from "framer-motion";
import { useInView } from "react-intersection-observer";
import { FaUserCheck, FaClipboardCheck } from "react-icons/fa";
import { BsFileText } from "react-icons/bs";
import { FaQuoteLeft, FaQuoteRight } from "react-icons/fa";

import { FaUsers } from "react-icons/fa6";
const stats = [
  {
    number: 10000,
    suffix: "+",
    description:
      "On the average recruiters can receive up to 10k-15k applications for a few jobs opening.",
    color: "bg-[#F08B75]",
    icon: BsFileText,
    image: null,
  },
  {
    number: 66,
    suffix: "%",
    description: "Apply without meeting job requirements",
    color: "bg-[#7C77C0]",
    // icon: FaUsers,
    image: null,
  },
  {
    number: 32,
    suffix: "%",
    description: "of candidates pass online test",
    color: "bg-[#D75893]",
    icon: FaClipboardCheck,
    // image: "https://images.unsplash.com/photo-1571844307880-751c6d86f3f3?auto=format&fit=crop&q=80&w=200"
  },
  {
    number: 73,
    suffix: "%",
    description:
      "Only 73% of eligible applicants show up for the next phase (online assessment)",
    color: "bg-[#5784B4]",
    icon: FaUserCheck,
    // image:
    //   "https://images.unsplash.com/photo-1573496359142-b8d87734a5a2?auto=format&fit=crop&q=80&w=200",
  },
];

const Counter = ({ value, suffix, duration = 2000 }) => {
  const [count, setCount] = useState(0);
  const { ref, inView } = useInView({
    triggerOnce: true,
    threshold: 0.1,
  });

  useEffect(() => {
    if (inView) {
      let startTime;
      const startValue = 0;
      const endValue = value;

      const animation = (currentTime) => {
        if (!startTime) startTime = currentTime;
        const progress = (currentTime - startTime) / duration;

        if (progress < 1) {
          const currentValue = Math.floor(
            startValue + (endValue - startValue) * progress
          );
          setCount(currentValue);
          requestAnimationFrame(animation);
        } else {
          setCount(endValue);
        }
      };

      requestAnimationFrame(animation);
    }
  }, [inView, value, duration]);

  return (
    <span ref={ref} className="text-white font-jakarta font-black">
      {count.toLocaleString()}
      {suffix}
    </span>
  );
};

const RecruitmentStats = () => {
  const containerVariants = {
    hidden: {},
    visible: {
      transition: {
        staggerChildren: 0.3,
      },
    },
  };

  const cardVariants = {
    hidden: {
      opacity: 0,
      y: 20,
    },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.5,
        ease: "easeOut",
      },
    },
  };

  return (
    <div className="py-16 px-4">
      <motion.div
        className="max-w-4xl mx-auto md:grid grid-cols-1 md:grid-cols-8 gap-6"
        variants={containerVariants}
        initial="hidden"
        animate="visible"
      >
        {/* 🟢 First Row - Two Cards (Each 2 Columns) */}
        {stats.slice(0, 2).map((stat, index) => (
          <motion.div
            key={index}
            variants={cardVariants}
            className={`${stat.color} mb-5 md:mb-0 rounded-3xl p-8 text-white flex items-center gap-4 relative overflow-hidden col-span-4`}
          >
            <div className="flex-1">
              <h2 className="text-5xl font-bold mb-4">
                <Counter value={stat.number} suffix={stat.suffix} />
              </h2>
              <p className="text-lg leading-tight text-white font-jakarta md:max-w-xs font-semibold mt-4">
                {stat.description}
              </p>
            </div> 
          </motion.div>
        ))}

        {/* 🟠 Second Row - 1 Column & 3 Columns */}
        {stats.slice(2, 4).map((stat, index) => (
          <motion.div
            key={index}
            variants={cardVariants}
            className={`${
              stat.color
            }  mb-5 md:mb-0 rounded-3xl p-8 text-white flex items-center gap-4 relative overflow-hidden ${
              index === 0 ? "col-span-3 w-full" : "col-span-5"
            }`}
          >
            <div className="flex-1">
              <h2 className="text-5xl font-bold mb-4">
                <Counter value={stat.number} suffix={stat.suffix} />
              </h2>
              <p className="text-xl leading-tight text-white font-jakarta max-w-xs font-semibold mt-4">
                {stat.description}
              </p>
            </div>
            {stat.image && (
              <div className="absolute hidden md:block right-4 top-1/2 -translate-y-1/2">
                <img
                  src={stat.image}
                  alt="card-image"
                  className="w-24 h-24 object-cover rounded-lg"
                />
              </div>
            )}
          </motion.div>
        ))}

        {/* 🟣 Third Row - Full Width */}
        <div className="col-span-8 w-full bg-purple-500 text-white p-6 rounded-3xl text-lg font-semibold flex flex-col items-center justify-center">
          <FaQuoteLeft className="text-3xl mb-2 self-start" />
          <p className="text-center max-w-2xl leading-relaxed text-white font-jakarta">
            The data fails to support the rationale for recruiters insisting on
            a 2:1 minimum as the benchmark for top talent.
          </p>
          <FaQuoteRight className="text-3xl mt-2 self-end" />
        </div>
      </motion.div>
    </div>
  );
};

export default RecruitmentStats;
