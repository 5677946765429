import React, { useState, useEffect, useRef } from "react";
import { motion } from "framer-motion";



const CustomBarChart = ({data}) => {
    const [counts, setCounts] = useState(Array(data.length).fill(0));
    const [visible, setVisible] = useState(false);
    const chartRef = useRef(null);
  
    // Intersection Observer to detect when the section is in view
    useEffect(() => {
      const observer = new IntersectionObserver(
        ([entry]) => {
          if (entry.isIntersecting) {
            setVisible(true);
          }
        },
        { threshold: 0.3 }
      );
  
      if (chartRef.current) observer.observe(chartRef.current);
      return () => observer.disconnect();
    }, []);

    useEffect(() => {
        if (visible) {
          data.forEach((_, index) => {
            setTimeout(() => {
              let count = 0;
              const interval = setInterval(() => {
                setCounts((prev) => {
                  const newCounts = [...prev];
                  if (count < data[index].value) {
                    count += 1;
                    newCounts[index] = count;
                  } else {
                    clearInterval(interval);
                  }
                  return newCounts;
                });
              }, 20);
            }, index * 400);
          });
        }
      }, [visible]);
  return (
    <div ref={chartRef} className="flex justify-center items-end gap-2 h-full my-6 px-4 md:px-0">
      {data.map((item, index) => (
        <div key={index} className="flex flex-col items-end">
          {/* Animated Bar */}
          <motion.div
            initial={{ height: 0 }}
            animate={visible ? { height: `${item.value * 3}px` } : {}}
            transition={{ duration: 0.8, delay: index * 0.4 }}
            // whileHover={{ scale: 1.1 }}
            className="w-[90px] md:w-[120px] text-3xl lg:text-5xl text-white font-bold  mt-2 flex items-end justify-center font-jakarta"
            style={{ backgroundColor: item.color }}
          >
            {counts[index]}%
          </motion.div>
          {/* Label */}
          <p className="text-black bg-white text text-sm mt-1 text-center w-[90px] md:w-[120px]">
            {item.label}
          </p>
        </div>
      ))}
    </div>
  );
};

export default CustomBarChart;
