import React, {useState, useEffect} from 'react'
import UpdatedNavbar from '../../layout/UpdateNavbar'
import Jumbotron from '../../components/Jumbotron'
import JobCard from './components/JobCard';
import { api } from '../../api/index';
import { useQuery } from 'react-query';
import ScaleLoader_ from '../../components/ScaleLoader';
import { compareSync } from 'bcryptjs';
import { useNavigate } from 'react-router-dom';
import Footer from '../../layout/Footer';
import Loader from '../../components/Loader';
import Pagination from '../../components/Pagination/Pagination';

const links_ = [ 
    { 
        path : '/',
        title : 'Home',
        slug : 'home',
     },
     { 
        path : '/job-listings',
        title : 'Job Listings',
        slug : 'job-listings',
     },
     { 
        path : `/bookmarks`,
        title : 'Bookmarks',
        slug : 'bookmarks',
     } 
 ]

const BookMark = () => {

    const [bookmarks, setBookmarks] = useState([])
    const navigate = useNavigate()
     const accountType = sessionStorage.getItem("account-type")
     const [status, setStatus] = useState(0)
     const user = JSON.parse(sessionStorage.getItem("user"));
    const [perPage, setPerPage] = useState(8)
     const [currentPage, setCurrentPage] = useState(1)
     
     const { data, isLoading, isError,} = useQuery(["get bookmarks", perPage, currentPage],  () => {
         if(compareSync("candidate", accountType)){
            return api.getBookmarks({
              userId: user?.user?.user_id,
              perPage, currentPage
            })
         }
    } );

    useEffect( () =>  {
        if(!compareSync("candidate", accountType)) navigate('/login')
        if(data) {
            setBookmarks(data)
            setStatus(2)
        }
    } , [data])
  
   if(isError){
       return <h5 className='text-center mt-[300px] text-md'>
            Error! an unkown error occured.
       </h5>
     }

  return (
    <div className='px-[4px] md:px-2 force-white-mode'>
      <UpdatedNavbar />
      <Jumbotron links={links_} active={'bookmarks'} />
       { isLoading ? <Loader /> :
         <>
            <h5 className='text-xl text-gray-950 text-center font-semibold mt-3'>
          My Bookmarks ({bookmarks?.meta?.total})
      </h5>
      <section className='mb-5 px-[8px] md:px-5 mt-5'>
      {
      (bookmarks?.data?.length ) ?
        <div className='grid grid-cols-1 md:grid-cols-2 gap-4'>
            { 
             bookmarks?.data?.map( bookmark => {
                return(
                    <JobCard 
                      title={bookmark?.campaign?.title}
                      listing={bookmark}
                      status = {null}
                      company={bookmark?.campaign?.client?.name}
                      type={''}
                      location={''}
                      date = { new Date(bookmark?.createdAt !== null && bookmark?.createdAt ) }
                      link={`/job-listings/apply/${bookmark?.campaign?.id}?campaign-id=${
                        bookmark?.campaign?.id}`}
                      key={bookmark?.id}
                />
                )
            }) 
            }
        </div> : status === 2 ? 
                <div className='my-5'>
                <h1 className='bi text-center bi-bag text-gray-600 text-[100px] mb-2'></h1>
                <p className='text-md text-center'>  
                         <span className='text-lg text-armyGreen'>Sorry</span>, 
                         there was no any bookmark found.
                 </p>
            </div>
            : null
         }
        {/* <Pagination jobs={bookmarks} setListings = { setBookmarks } /> */}
        { 
          <Pagination 
            data={bookmarks?.meta}
            pageNo={currentPage}
            perPage={perPage}
            setPageNo={setCurrentPage}
            setPerPage={setPerPage} 
        />}
      </section>
         </>
       }
      <Footer />
    </div>
  )
}

export default BookMark